import * as React from 'react';
import { compose } from '../../../model/helpers/compose';
import { Keyboard } from './Keyboard';
import {
  withKeyboardController,
  WithKeyboardControllerProps,
} from '../../../model/providers/KeyboardProvider/KeyboardProvider';

export type KeyboardHolderProps = {} & WithKeyboardControllerProps;

class KeyboardHolder extends React.Component<KeyboardHolderProps> {

  render() {
    const { visible, onKeyPressed, hide } = this.props.KeyboardController;

    return (
      <Keyboard onKeyPressed={onKeyPressed} onDownKeyboard={hide} collapsed={!visible}/>
    )
  }
}

export default compose(
  withKeyboardController
)(KeyboardHolder);
