import gql from 'graphql-tag';
import Fragment from './Fragment';

const queries = {

  me : gql`
    query {
      me {
        ...UserFragment
      }
    }
    ${Fragment.UserFragment}
  `,

  cloudScreen: gql`
    query cloudScreen($id: ID!) {
      cloudScreen(id: $id) {
        id
        canContactManager
        canContactLeasingManager
        status
        accessPoint {
          id
          name
          status
          allowVendors
          property {
            ...PropertyFragment
          }
        }
      }
    }
    ${Fragment.PropertyFragment}
  `,

  property : gql`
    query property($id: ID!) {
      property(id: $id) {
        ...PropertyFragment
      }
    }
    ${Fragment.PropertyFragment}
  `,

  accessPoint : gql`
    query accessPoint($id: ID!) {
      accessPoint(id: $id) {
        ...AccessPointFragment
        property {
          ...PropertyFragment
        }
      }
    }
    ${Fragment.AccessPointFragment}
    ${Fragment.PropertyFragment}
  `,
  pndContacts : gql`
    query pndContacts($accessPointId: ID!, $order: DateNameOrder = NAME, $direction: OrderDirection = ASC) {
      pndContacts(accessPointId: $accessPointId, order: $order, direction: $direction) {
        id
        name
        type
        unit
        image {
          id
          url
        }
      }
    }
  `,

  vendors: gql`
    query vendors($propertyId: ID, $limit: Int, $offset: Int) {
      vendors(propertyId: $propertyId, limit: $limit, offset: $offset) {
        edges {
          node {
            ...VendorFragment
          }
        }
      }
    }
    ${Fragment.VendorFragment}
  `,
  firmware: gql`
    query firmware($id: ID!, $token: String) {
      firmware(id: $id, token: $token) {
        ...FirmwareFragment
      }
    }
    ${Fragment.FirmwareFragment}
  `,
};

export default queries;
