import React, { Component } from 'react';
import { Spring } from 'react-spring/renderprops';
import Icon from '../../../../../assets/Icon/Icon';
import Sound from '../../../../../assets/sounds';
import { ACTION_BACK_TIMEOUT } from '../../../../../model/Constants';
import { AccessPoint } from '../../../../../model/data/AccessPoint';
import { getMediaPath } from '../../../../../model/helpers/ElectronUtils';
import { CodeType } from '../../CodeScreen';
import './CodeGranted.sass';

type Props = {
  type: CodeType
  accessPoint: AccessPoint
  onComplete: Function
};

class CodeGranted extends Component<Props> {

  state = {
    forward : true,
    back    : false,
  }

  timeoutId: any;
  soundRef: HTMLAudioElement = new Audio(getMediaPath(Sound.openSuccess));

  componentDidMount(): void {
    this.timeoutId = setTimeout(() => {
      this.props.onComplete()
    }, ACTION_BACK_TIMEOUT);

    this.soundRef.play();
  }

  componentWillUnmount(): void {
    this.clearTimeout();
  }

  onBackAction = () => {
    this.clearTimeout();
    this.setState({ forward : false, back : true }, () =>
      setTimeout(() => this.props.onComplete(), 500));
  };

  clearTimeout = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }

    this.soundRef.pause();
  };

  render() {
    const { accessPoint, type } = this.props;
    const { forward, back } = this.state;
    const isGuest = type === CodeType.guest;

    return (
      <div className='code-granted__wrapper' onClick={() => this.onBackAction()}>
        <div className='code-granted'>
          <Spring
            reset={true}
            reverse={!forward}
            from={{
              opacity   : forward ? 1 : back ? 0 : 1,
              width     : back ? 600 : 520,
              marginTop : forward ? 80 : back ? 400 : 80,
            }}
            to={{
              opacity   : 1,
              width     : 600,
              marginTop : 300
            }}>{props =>
            <Icon.CloudSmile style={props} className='cloud'/>
          }</Spring>
          <Spring
            reset={true}
            reverse={!forward}
            delay={back ? 0 : 200}
            from={{ opacity : 0, marginTop : back ? 20 : 80 }}
            to={{ opacity : 1, marginTop : 20 }}>{props =>
            <div style={props} className="code-granted__text-title">Welcome!</div>
          }</Spring>
          <Spring
            reset={true}
            reverse={!forward}
            delay={back ? 0 : 200}
            from={{ opacity : 0, marginTop : back ? -70 : -130 }}
            to={{ opacity : 1, marginTop : -70 }}>{props =>
            <div style={props} className='code-granted__text-subtitle'>
              {isGuest ? <>Access Granted.</> : <><span className='code-granted__text-door'>{accessPoint.name}</span> is
                open!</>}
            </div>
          }</Spring>
        </div>
      </div>
    );
  }
}

export default CodeGranted;
