import * as React from 'react'
import InviteFailed from './failed/InviteFailed';
import InviteInput from './input/InviteInput';
import InviteSuccess from './success/InviteSuccess';
import './InviteScreen.sass';

type Props = {
  type: string
  onBack: Function
  onLockout: Function
  onSettings: Function
};

type State = {
  view: ViewType
}

enum ViewType {
  input = 'input',
  success = 'success',
  failed = 'failed'
}

class InviteScreen extends React.Component<Props, State> {

  state = {
    view: ViewType.input,
  };

  onCloseSelect = () => this.props.onBack();
  onLockout = () => this.props.onLockout();
  onSettings = () => this.props.onSettings();
  onTryAgainSelect = () => this.setState({ view: ViewType.input });
  onSendSuccess = () => this.setState({ view: ViewType.success });
  onSendFailure = () => this.setState({ view: ViewType.failed });

  render() {

    return (
      <div className='invite-screen'>{
        (() => {
          switch (this.state.view) {
            case ViewType.input:
              return <InviteInput
                type={this.props.type}
                onBack={this.onCloseSelect}
                onLockout={this.onLockout}
                onSettings={this.onSettings}
                onSendSuccess={this.onSendSuccess}
                onSendFailure={this.onSendFailure} />;

            case ViewType.success:
              return <InviteSuccess
                onComplete={this.onCloseSelect} />;

            case ViewType.failed:
              return <InviteFailed
                onComplete={this.onTryAgainSelect} />
          }
        })()
      }</div>
    );
  }
}

export default InviteScreen;
