import * as React from 'react';
import { WithApolloClient } from '@apollo/client/react/hoc';
import { compose } from '../../../model/helpers/compose';
import { createLogger } from '../../../model/helpers/Logger';
import { AuthControllerProps, withAuthController } from '../../../model/providers/AuthProvider';
import { withWebSocketClient, WithWebSocketProps } from '../../../model/providers/WebSocketProvider';
import AppEventDispatcher, { AppEventType } from '../../../model/services/AppEventDispatcher';
import { Button } from '../../views/button/Button';
import { Spinner } from '../../views/spinner/Spinner';
import './PendingScreen.sass';

const logger = createLogger('[PendingScreen]');

type Props = WithApolloClient<{} & AuthControllerProps & WithWebSocketProps>

class PendingScreen extends React.Component<Props> {

  componentDidMount() {
    AppEventDispatcher.addListener(AppEventType.ACTIVATED, this.onActivated);
  }

  componentWillUnmount(): void {
    AppEventDispatcher.removeListener(AppEventType.ACTIVATED);
  }

  onActivated = (data: any) => {
    const { authController } = this.props;

    if (data.accessToken) {
      authController.activate(data);
    } else {
      logger.i(`activation doesn't contain 'accessToken'`)
    }
  };

  onCancelRegistration = () => {
    this.props.authController.logout();
  };

  render() {
    return (
      <div className={'pending-screen'}>
        <div className={'pending-screen__info-holder'}>
          <Spinner size={200}/>
          <span className={'pending-screen__text'}>Waiting for Cloud Screen activation...</span>
          <Button className={'pending-screen__button'} title={'Cancel & Start Over'} height={120}
                  onClick={this.onCancelRegistration}/>
        </div>
      </div>
    );
  }
}

export default compose(withAuthController, withWebSocketClient)(PendingScreen);
