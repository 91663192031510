import { RefObject } from 'react';
import * as React from 'react';
import './TextInput.sass';
import { SPECIAL_KEY, SPECIAL_KEY_BY_INDEX } from '../../../model/providers/KeyboardProvider/KeyboardSettings';
import {
  KEYBOARD_EVENT,
  withKeyboardController,
  WithKeyboardControllerProps
} from '../../../model/providers/KeyboardProvider/KeyboardProvider';
import { compose } from '../../../model/helpers/compose';

type TextInputProps = {
  className?: string
  type?: string
  name: string
  value: string
  placeholder?: string
  autoComplete?: string
  inputRef?: RefObject<HTMLInputElement>
  onChange: (value: string) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onEnter?: any
} & WithKeyboardControllerProps

type State = {
  focused: boolean
}

class TextInput extends React.Component<TextInputProps, State> {

  static defaultProps = {
    className : 'text-input',
    autoComplete: 'off'
  };

  inputRef = React.createRef<HTMLInputElement>();

  state = {
    focused : false
  };

  constructor(props: TextInputProps){
    super(props);

    if (props.inputRef) {
      this.inputRef = props.inputRef;
    }
  }

  componentDidMount() {
    this.props.KeyboardController.addEventListener(KEYBOARD_EVENT.keyPressed, this.onKeyPressed);
    this.props.KeyboardController.addEventListener(KEYBOARD_EVENT.keyboardDown, this.onKeyboardDown);
  }

  componentWillUnmount() {
    this.props.KeyboardController.removeEventListener(KEYBOARD_EVENT.keyPressed, this.onKeyPressed);
    this.props.KeyboardController.removeEventListener(KEYBOARD_EVENT.keyboardDown, this.onKeyboardDown);
    this.props.KeyboardController.hide();
  }

  onKeyboardDown = () => {
    if (this.state.focused && this.inputRef.current) {
      this.inputRef.current.blur();
    }
  };

  onKeyPressed = (key: string) => {
    if (!this.state.focused) return;

    if (SPECIAL_KEY_BY_INDEX[key]) {
      this.handleSpecialKey(key);
    } else {
      this.props.onChange(this.props.value + key);
    }
  };

  handleSpecialKey = (key: string) => {
    switch (key) {
      case SPECIAL_KEY.space:
        this.props.onChange(this.props.value + ' ');
        break;
      case SPECIAL_KEY.bksp:
        this.props.onChange(this.props.value.length > 0 ? this.props.value.slice(0, -1) : '');
        break;
      case SPECIAL_KEY.clear:
        this.props.onEnter && this.props.onEnter();
        break;
      default:
        console.log('textInput: unknown special key:', key);
        break;
    }
  };

  onFocus = () => this.setState({ focused : true }, () => this.props.KeyboardController.show());
  onBlur = () => this.setState({ focused : false });

  render() {
    const { className, value, name, placeholder, autoComplete, onKeyDown, type } = this.props;
    return <input
      className={className}
      placeholder={placeholder}
      value={value}
      type={type}
      name={name}
      onKeyDown={onKeyDown}
      onFocus={this.onFocus}
      onBlur={this.onBlur}
      onChange={(e) => this.props.onChange(e.target.value)}
      autoComplete={autoComplete}
      ref={this.inputRef}
    />
  }
}

export default compose(
  withKeyboardController
)(TextInput);
