import React, { Component } from 'react';
import Icon from '../../../../../assets/Icon/Icon';
import { Button } from '../../../../views/button/Button'
import './EmergencyDenied.sass';

type Props = {
  onRetry: Function
  onComplete: Function
};

class EmergencyDenied extends Component<Props> {

  timeoutId: any;

  componentDidMount(): void {
    this.timeoutId = setTimeout(() => {
      this.props.onComplete()
    }, 10000)
  }

  componentWillUnmount(): void {
    this.clearTimeout();
  }

  onRetryAction = () => {
    this.clearTimeout();
    this.props.onRetry();
  };

  clearTimeout = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  };

  render() {
    return (
      <div className='emergency-denied'>
        <Icon.CloudSad style={{ width : 600, height: 400 }}/>
        <div className='emergency-denied__message'>
          The Access Code<br/>is <span className='emergency-denied__message-bold'>incorrect!</span>
        </div>
        <Button className='emergency-denied__cancel-button' onClick={this.onRetryAction} height={100}>
          Try Again
        </Button>
      </div>
    );
  }
}

export default EmergencyDenied;
