import { ApolloClient, ApolloLink, from, InMemoryCache, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { createLogger } from '../../helpers/Logger';
import StorageService from '../StorageService';

function baseFromUrl(url: string): string | undefined {
  const urlObject = new URL(url);
  return urlObject.origin;
}

const logger = createLogger('[ApolloClient]');

const apiUrl = process.env.REACT_APP_API_ENDPOINT || `http://localhost:3010/`;
const domain = baseFromUrl(apiUrl);

const fetchRefreshToken = async () => {
  const url = new URL(domain + '/oauth/token');

  const refreshToken = StorageService.getItem('refreshToken');
  if (!refreshToken) {
    logger.e('Refresh token is not provided');
    //authProviderInstance.logout();
    return;
  }

  url.searchParams.append('token', refreshToken);
  url.searchParams.append('grant_type', 'refresh_token');

  let refreshResult;
  try {
    // console.log('fetching from', url);
    refreshResult = await fetch(url + '').then(r => r.json());
  } catch (e) {
    logger.e('Cannot obtain a new token', e);
    //authProviderInstance.logout();
    return;
  }

  return refreshResult;
};

const promiseToObservable = (promise: any) =>
  new Observable((subscriber: any) => {
    promise.then(
      (value: any) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err: any) => subscriber.error(err),
    );
    return subscriber; // this line can removed, as per next comment
  });

const httpLink = createUploadLink({ uri : apiUrl });

const loggingRequestLink = new ApolloLink((operation, forward) => {
  // logger.i('%c Request Payload ', 'background: rgba(74,144,226, .3);', {
  //   variables : operation.variables,
  //   query     : print(operation.query),
  // });

  operation.setContext(({ headers = {} }: any) => {
    const token = StorageService.getItem('accessToken');
    if (token) {
      return {
        headers : {
          ...headers,
          Cloudscreen : token ? token : null,
        },
      };
    }
  });

  return forward(operation);
});

const loggingResponseLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    // logger.i('%c Response Payload ', 'background: rgba(74,226, 144, .3);', response.data || '');
    return response;
  });
});

const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {

  if (graphQLErrors) {
    if (graphQLErrors[0].message.includes('jwt expired')) {
      return promiseToObservable(fetchRefreshToken()).flatMap((data: any) => {
        if (data) {
          StorageService.setItem('accessToken', data.accessToken);
          operation.setContext(({ headers = {} }: any) => ({
            headers : {
              ...headers,
              Authorization : `Bearer ${data.accessToken}`,
            },
          }));
        }
        return forward(operation);
      });
    }
    return;
  }

  if (networkError) {
    logger.e(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link  : from([loggingRequestLink, loggingResponseLink, errorLink, httpLink]),
  cache : new InMemoryCache(),
});

export { client }
