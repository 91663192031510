import { IS_RPI_ELECTRON } from '../helpers/ElectronUtils';
import StorageService from './StorageService';
import { createLogger } from '../helpers/Logger';

const logger = createLogger('[MediaStreamService]');

class MediaStreamService {

  _mediaStream: MediaStream | null = null;
  _videoDeviceId: string | null = null;
  _audioDeviceId: string | null = null;
  isRpiElectron: boolean;
  isAudioEnabled: boolean | null = null;

  private static _instance: MediaStreamService = new MediaStreamService();

  constructor() {
    logger.i('callling media strream creation');
    if (MediaStreamService._instance) {
      throw new Error('Error: Instantiation failed: Use SingletonClass.getInstance() instead of new.');
    }
    logger.i('getting back media strream');
    MediaStreamService._instance = this;
    this.isRpiElectron = IS_RPI_ELECTRON;
    this.setDeviceIds();
  }

  public static getInstance(): MediaStreamService {
    return MediaStreamService._instance;
  }

  setDeviceIds = () => {
    const audioId = StorageService.getItem('audioDeviceId');
    const videoId = StorageService.getItem('videoDeviceId');

    if (audioId !== null && videoId !== null) {
      this._videoDeviceId = videoId;
      this._audioDeviceId = audioId
    }
  }

  getMediaDevices = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      logger.i('enumerateDevices() not supported.');
      return null;
    }

    return await navigator.mediaDevices.enumerateDevices();
  }

  startStream = (audioEnabled: boolean = true) => {
    this.isAudioEnabled = audioEnabled;

    return new Promise<MediaStream | undefined>((resolve, reject) => {
      let mediaSupport = 'mediaDevices' in navigator;

      //if (!this._audioDeviceId || !this._videoDeviceId) {
      //  logger.i('Video/Audio devices not set properly.');
      //  resolve(undefined);
      //}

      if (mediaSupport) {
        //const videoConstrains = this._videoDeviceId !== null ? {
        //  deviceId : { exact : this._videoDeviceId },
        //  width: 1280,
        //  height: 720
        //} : false;
        //const audioConstrains = audioEnabled && this._audioDeviceId ? { deviceId : { exact : this._audioDeviceId } } : false;
        const videoConstrains = true;
        const audioConstrains = true;

        navigator.mediaDevices.getUserMedia({
          video : videoConstrains,
          audio : audioConstrains
        })
          .then((mediaStream) => {
            logger.i('getting a stream here', mediaStream);
            this._mediaStream = mediaStream;
            resolve(this._mediaStream);
          })
          .catch((err: any) => {
            logger.e('Unable to access media devices: ');
            logger.e(err);
            resolve(undefined);
          });
      } else {
        alert('Your browser does not support media devices.');
        resolve(undefined);
      }
    });
  };

  getStream = async (audioEnabled: boolean = true) => {
    if (this.isAudioEnabled !== audioEnabled && this._mediaStream) {
      this.stopStream()
      return await this.startStream(audioEnabled);
    }

    if (this._mediaStream) {
      return this._mediaStream;
    } else {
      logger.i('No existing stream! Starting new one...');
      return await this.startStream(audioEnabled);
    }
  };

  stopStream = (shouldResetUsbCamera: boolean = false) => {
    logger.i('STOPPING STREAM');
    if (this._mediaStream) {
      this._mediaStream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
      this._mediaStream = null;

      // if (shouldResetUsbCamera && this.isRpiElectron) {
      //   setTimeout(() => {
      //     execWithPromise(`cd ${PI_PATH} && ./resetUsbCamera.py`);
      //   }, 2000)
      // }
    }
  };

}

export default MediaStreamService;
