import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from '../../../model/helpers/compose';
import { AuthControllerProps, withAuthController } from '../../../model/providers/AuthProvider';
import CodeDenied from './views/denied/CodeDenied';
import CodeGranted from './views/granted/CodeGranted';
import CodeInput from './views/input/CodeInput';
import CodeTypeView from './views/type/CodeTypeView';
import './CodeScreen.sass'

export enum CodeType {
  resident = 'resident',
  guest = 'guest',
}

enum ViewState {
  type = 'type',
  input = 'input',
  granted = 'granted',
  denied = 'denied'
}

type Props = RouteComponentProps & AuthControllerProps

type State = {
  view: ViewState,
  type: CodeType | undefined,
  retry: boolean,
}

class CodeScreen extends Component<Props, State> {

  state = {
    view : ViewState.type,
    type : undefined,
    retry: false,
  };

  onCloseAction = () => this.props.history.goBack();
  onRetryAction = () => this.setState({ view : ViewState.type, retry: true });
  onTypeAction = (type: CodeType) => this.setState({ view : ViewState.input, type : type });
  onCodeGranted= (type: CodeType) => this.setState({ view : ViewState.granted, type : type });
  onCodeDenied = (type: CodeType) => this.setState({ view : ViewState.denied, type : type });

  renderContent = () => {
    const { view, type, retry } = this.state;
    const { authController } = this.props;
    //const accessPoint = authController.cloudScreen!.accessPoint;

    switch (view) {
      case ViewState.type:
        return <CodeTypeView
          initial={!retry}
          onBack={this.onCloseAction}
          onComplete={this.onTypeAction}/>;

      case ViewState.input:
        return <CodeInput
          type={type!}
          accessPoint={authController.accessPoint!}
          onBack={this.onRetryAction}
          onGranted={this.onCodeGranted}
          onDenied={this.onCodeDenied} />;

      case ViewState.granted:
        return <CodeGranted
          type={type!}
          accessPoint={authController.accessPoint!}
          onComplete={this.onCloseAction}/>;

      case ViewState.denied:
        return <CodeDenied
          type={type!}
          onRetry={this.onRetryAction}
          onComplete={this.onCloseAction}/>;
    }
  };

  render() {
    return (
      <div className="code-screen">{this.renderContent()}</div>
    );
  }
}

export default compose(
  withRouter,
  withAuthController
)(CodeScreen);
