import * as React from 'react'
import ReactDOM from 'react-dom';
import './Modal.sass';

class Modal extends React.Component<{}> {
  render() {
    return ReactDOM.createPortal(
      <div className='modal'>
        {this.props.children}
      </div>,
      document.body
    )
  }
}

export default Modal