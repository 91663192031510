import * as React from 'react';
import MediaStreamService from '../../../model/services/MediaStreamService';

type CameraProps = {
  captureQuality: number,
  captureWidth: number,
  captureHeight: number,
  captureFormat: string,
  containerClassName: string | null,
  videoClassName: string | null,
  audioMuted: boolean
  stopStreamOnUnmount: boolean
}

class Camera extends React.Component<CameraProps> {
  static defaultProps = {
    captureQuality      : 1,
    captureWidth        : 1280,
    captureHeight       : 720,
    captureFormat       : 'image/jpeg',
    containerClassName  : null,
    videoClassName      : null,
    audioMuted          : true,
    stopStreamOnUnmount : true
  };

  mediaStreamService: MediaStreamService | null = null;
  cameraStream: any = React.createRef();
  canvas: any = React.createRef();

  componentDidMount() {
    this.startStreaming();
  }

  componentWillUnmount() {
    if (this.props.stopStreamOnUnmount) {
      this.stopStreaming();
    }
  }

  startStreaming = async () => {
    this.mediaStreamService = MediaStreamService.getInstance();

    const mediaStream = await this.mediaStreamService.getStream(!this.props.audioMuted);

    if (mediaStream) {
      this.cameraStream.srcObject = mediaStream;
      this.cameraStream.play();
    } else {
      this.mediaStreamService = null;
    }
  };

  stopStreaming = () => {
    if (this.mediaStreamService) {
      this.cameraStream.pause();
      this.mediaStreamService.stopStream();
    }
  };

  captureImage = () => {
    if (this.mediaStreamService) {
      let ctx = this.canvas.getContext('2d');
      ctx.translate(this.props.captureWidth, 0);
      ctx.scale(-1, 1);
      ctx.drawImage(this.cameraStream, 0, 0, this.props.captureWidth, this.props.captureHeight);
      return this.canvas.toDataURL(this.props.captureFormat, this.props.captureQuality);
    }
  };

  render() {
    const { captureWidth, captureHeight, containerClassName, videoClassName, audioMuted } = this.props;

    return (
      <div className={containerClassName || ''}>
        <video
          className={videoClassName || ''}
          ref={(stream) => {
            this.cameraStream = stream
          }}
          muted={audioMuted}
          width={captureWidth}
          height={captureHeight}
          style={videoClassName ? { transform: 'rotateY(180deg)'} : { display : 'none' }}
        />
        <canvas
          ref={(canvas) => {
            this.canvas = canvas
          }}
          width={captureWidth}
          height={captureHeight}
          style={{ display : 'none' }}
        />
      </div>
    )
  }
}

export { Camera };

