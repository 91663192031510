import React, { Component } from 'react';
import Icon from '../../../..//assets/Icon/Icon';
import Sound from '../../../../assets/sounds';
import { AccessPoint } from '../../../../model/data/AccessPoint';
import { getMediaPath } from '../../../../model/helpers/ElectronUtils';
import './WelcomeScreen.sass';

type Props = {
  accessPoint: AccessPoint
  onComplete: Function
};

class WelcomeScreen extends Component<Props> {

  timeoutId: any;
  soundRef: HTMLAudioElement = new Audio(getMediaPath(Sound.openSuccess));

  componentDidMount(): void {
    this.timeoutId = setTimeout(() => {
      this.props.onComplete()
    }, 5000);

    this.soundRef.play();
  }

  componentWillUnmount(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }

    this.soundRef.pause();
  }

  render() {
    const { accessPoint } = this.props;
    let text = 'Welcome!';
    if (accessPoint && accessPoint.property.type === 'MF') {
      text = 'Welcome Home!';
    }
    const accessPointName = accessPoint ? accessPoint.name : 'Door';

    return (
      <div className='granted-view__wrapper' onClick={() => this.props.onComplete()}>
        <div className='granted-view' onClick={() => this.props.onComplete()}>
          <Icon.CloudSmile style={{ width : 440 }}/>
          <div className="granted-view__text-title">{text}</div>
          <div className='granted-view__text-subtitle'>
            <span className='granted-view__text-door'>{accessPointName}</span> is open!
          </div>
        </div>
      </div>
    );
  }
}

export default WelcomeScreen;
