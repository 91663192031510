import * as React from 'react';
import { TransitionGroup } from 'react-transition-group';
import { compose } from '../../../model/helpers/compose';
import BasicNotification from './Notification';
import {
  withNotificationController,
  WithNotificationController,
} from '../../../model/providers/NotificationProvider';

export type NotificationHolderProps = {
  name?: string
} & WithNotificationController;

class NotificationHolder extends React.Component<NotificationHolderProps> {
  render() {
    const { notifications } = this.props.NotificationController;
    return <>
      <TransitionGroup
        transitionName='slide-down'
        transitionAppear={false}
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}>
        {notifications.map((item: any) => <BasicNotification key={item.id} {...item} />)}
      </TransitionGroup>
    </>
  }
}
// CSSTransition + TransitionGroup now
export default compose(
  withNotificationController
)(NotificationHolder);
