import React, { Component } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { CallCaller } from '../../../../../model/data/CallCaller';
import { CallRecipient } from '../../../../../model/data/CallRecipient';
import { Image } from '../../../../../model/data/Image';
import { compose } from '../../../../../model/helpers/compose';
import FileHelper from '../../../../../model/helpers/FileHelper';
import { createLogger } from '../../../../../model/helpers/Logger';
import Mutations from '../../../../../model/services/graphql/Mutations';
import './CallerView.sass';
import CallerNameView from './name/CallerNameView';
import CallerPhotoView from './photo/CallerPhotoView';

const logger = createLogger('[CallerView]');

type Props = WithApolloClient<{
  recipient: CallRecipient,
  onBack: Function
  onComplete: Function,
}>;

class CallerView extends Component<Props> {

  state = {
    name : null,
  };

  uploadBase64Image = (base64Image: string) => {
    const file = FileHelper.dataURLtoFile(base64Image, 'screenshot.jpg');
    const variables = { field : 'file', file : file };

    return this.props.client!.mutate({
        mutation  : Mutations.uploadFile,
        variables : variables,
      })
      .then((response: any) => response.data.uploadFile)
      .catch((err) => logger.e(err));
  };

  onCancel = () => {
    this.setState({ name : null });
    this.props.onBack();
  };

  onCompleteName = (name: string) => {
    this.setState({ name : name });
  };

  onCompletePhoto = async (base64: string) => {
    //const image = { id: '5d2db24ad0a1f7001ed411b4', url : 'https://www.googleapis.com/download/storage/v1/b/cloudkeyz-dev/o/2019%2F7%2F16%2F9659e22c3f3c9768d14fda00dea0fe30287a611f.jpeg?generation=1563275849977778&alt=media' } as Image

    const image = await this.uploadBase64Image(base64) as Image;
    const caller = { name : this.state.name, image : image };
    // MediaStreamService.getInstance().stopStream();
    this.props.onComplete(caller as CallCaller);
  };


  render() {
    const { name } = this.state;

    return (
      <div className="caller-view">
        <div className="content">{name == null
          ? <CallerNameView onCancel={this.onCancel} onComplete={this.onCompleteName} recipient={this.props.recipient}/>
          : <CallerPhotoView onComplete={this.onCompletePhoto}/>
        }</div>
      </div>
    );
  }
}

export default compose(
  withApollo,
)(CallerView);
