import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import Icon from '../../../assets/Icon/Icon';
import { CallRecipient, CallRecipientType } from '../../../model/data/CallRecipient';
import { Tenant } from '../../../model/data/Tenant';
import { compose } from '../../../model/helpers/compose';
import { AuthControllerProps, withAuthController } from '../../../model/providers/AuthProvider';
import { CallProviderProps, withCallController } from '../../../model/providers/CallProvider';
import { Button, ButtonColors, ButtonTypes } from '../../views/button/Button';
import PndHeader from './PndHeader';
import PndList from './PndList';
import './PndScreen.sass';

type Props =
  CallProviderProps
  & RouteComponentProps
  & AuthControllerProps
  & { callController: CallProviderProps }

type State = {
  searchQuery?: string,
}

class PndScreen extends Component<Props, State> {

  //@todo: Fix scroll view

  state = {
    searchQuery : ''
  };

  onSearchChange = (searchQuery: string | undefined) => {
    this.setState({ searchQuery })
  };

  onTenantSelect = (tenant: Tenant) => {
    const recipient = new CallRecipient(CallRecipientType.tenant, tenant);
    this.props.callController.initiateCall(recipient);
  };

  onManagerSelect = () => {
    const user = { firstName : 'Property', lastName : 'Manager' };
    const recipient = new CallRecipient(CallRecipientType.manager, user);
    this.props.callController.initiateCall(recipient);
  };

  render() {
    const { cloudScreen } = this.props.authController;
    const { searchQuery } = this.state;

    if (!cloudScreen) return;

    return (
      <div className='pnd-screen'>
        <PndHeader
          property={cloudScreen.accessPoint.property}
          searchQuery={searchQuery}
          onSearchChange={this.onSearchChange}/>
        <PndList
          searchQuery={searchQuery}
          onTenantSelect={this.onTenantSelect}/>
        {cloudScreen.canContactManager &&
        <div className='pnd-footer'>
          <div className='divider'/>
          <Button
            style={{ width : 540 }}
            height={110}
            type={ButtonTypes.outlined}
            color={ButtonColors.white}
            onClick={this.onManagerSelect}>
            <Icon.Person style={{ marginRight : 20, width: 32, height: 32 }}/>
            <span style={{ fontWeight : 600 }}>Property Manager</span>
          </Button>
        </div>}
      </div>
    )
  }
}

export default compose(
  withCallController,
  withAuthController
)(PndScreen);
