import cn from 'classnames';
import * as React from 'react';
import { Transition, animated } from 'react-spring/renderprops'
import './Tabs.sass';

export class Tab {
  id: string;
  label: string;
  icon: any;

  constructor(id: string, label: string, icon: any) {
    this.id = id;
    this.label = label;
    this.icon = icon;
  }
}

type Props = {
  tabs: Tab[]
  initialId: string,
  onSelectTab: (id: string) => void
  forward: boolean
};

interface State {
  activeId: string
}

class Tabs extends React.Component<Props, State> {

  indicatorRef = React.createRef<HTMLDivElement>();
  tabsHolderRef = React.createRef<HTMLDivElement>();

  timeoutId: any;

  state = {
    activeId : this.props.initialId
  };

  componentDidMount(): void {
    setTimeout(() => this.setActiveTabWith(this.state.activeId), 100);
  }

  componentWillUnmount(): void {
    clearTimeout(this.timeoutId);
  }

  setActiveTabWith = (id: string) => {
    const indicator = this.indicatorRef.current;
    const tabsHolder = this.tabsHolderRef.current;
    const tab = document.querySelector(`#${id}`);

    if (!indicator || !tab || !tabsHolder) return;

    const indicatorRect = indicator.getBoundingClientRect();
    const tabsHolderRect = tabsHolder.getBoundingClientRect();
    const tabRect = tab.getBoundingClientRect();

    if (tabRect.right > indicatorRect.right) {
      indicator.style.right = tabsHolderRect.right - tabRect.right + 'px';
      this.timeoutId = setTimeout(() => {
        indicator.style.left = tabRect.left - tabsHolderRect.left + 'px';
      }, 120)
    } else {
      indicator.style.left = tabRect.left - tabsHolderRect.left + 'px';
      this.timeoutId = setTimeout(() => {
        indicator.style.right = tabsHolderRect.right - tabRect.right + 'px';
      }, 120)
    }
  };

  onSelectTab = (tab: Tab) => {
    this.setState(
      { activeId : tab.id },
      () => {
        this.setActiveTabWith(tab.id);
        this.props.onSelectTab(tab.id);
      });
  };

  renderTabs= () => {
    return this.props.tabs.map(tab => (
      <button
        className={cn('tabs__item', { '-active' : tab.id === this.state.activeId })}
        key={tab.id}
        id={tab.id}
        onClick={() => this.onSelectTab(tab)}>{
        tab.icon}{tab.label}
      </button>
    ))
  }

  render() {
    return (
      <Transition
        native
        delay={this.props.forward ? 0 : 0}
        items={this.props.forward}
        from={{ marginTop : 100, opacity: 0 }}
        enter={[{ marginTop : 0, opacity: 1 }]}
        leave={{ marginTop : 60, opacity: 0 }}>
        {show =>
          // @ts-ignore
          show && (props => <animated.div style={props}>
            <div ref={this.tabsHolderRef} className='tabs'>
              {this.renderTabs()}
              <div ref={this.indicatorRef} className='tabs__indicator'/>
            </div>
          </animated.div>)
        }
      </Transition>
    );
  }
}

export default Tabs;
