import * as React from 'react';
import { RouterProps, withRouter } from 'react-router';
import Icon from '../../../assets/Icon/Icon';
import { compose } from '../../../model/helpers/compose';
import MediaStreamService from '../../../model/services/MediaStreamService';
import StorageService from '../../../model/services/StorageService';
import { urlTo } from '../../app/AppRoute';
import { Button } from '../../views/button/Button';
import { Spinner } from '../../views/spinner/Spinner';
import './SettingsScreen.sass';

class SettingsScreen extends React.Component<RouterProps, {}> {

  mediaStreamService: MediaStreamService = MediaStreamService.getInstance();

  state = {
    loading               : true,
    audioDevices          : [],
    videoDevices          : [],
    selectedAudioDeviceId : null,
    selectedVideoDeviceId : null
  }

  componentDidMount() {
    this.getDevices();
  }

  getDevices = async () => {
    const devices = await this.mediaStreamService.getMediaDevices() || [];
    const currentlySelectedVideoId = StorageService.getItem('videoDeviceId');
    const currentlySelectedAudioId = StorageService.getItem('audioDeviceId');

    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    const audioDevices = devices.filter(device => device.kind === 'audioinput');

    this.setState({
      selectedVideoDeviceId : currentlySelectedVideoId
        ? currentlySelectedVideoId
        : videoDevices.length > 0 ? videoDevices[0].deviceId : null,
      selectedAudioDeviceId : currentlySelectedAudioId
        ? currentlySelectedAudioId
        : audioDevices.length > 0 ? audioDevices[0].deviceId : null,
      loading               : false,
      videoDevices,
      audioDevices
    });
  }

  onChangeHandler = (key: string) => (value: string | null) => this.setState({ [key] : value })

  onContinue = (videoDeviceId: string | null, audioDeviceId: string | null) => {
    if (videoDeviceId && audioDeviceId) {
      StorageService.setItem('videoDeviceId', videoDeviceId);
      StorageService.setItem('audioDeviceId', audioDeviceId);
      this.mediaStreamService.setDeviceIds();
      this.props.history.push(urlTo('home'))
    } else {
      alert('Failed to set Video and Audio devices.')
    }
  };

  renderDevice = (deviceInfo: MediaDeviceInfo, currentlySelectedDevice: string | null, keyToChange: string) => {
    const isSelected = deviceInfo.deviceId === currentlySelectedDevice;

    return (
      <div className={'settings-screen__device-holder'} key={deviceInfo.deviceId}
           onClick={() => this.onChangeHandler(keyToChange)(deviceInfo.deviceId)}>
        <span className={`settings-screen__device-label ${isSelected && 'selected'}`}>{deviceInfo.label || 'unnamed'}</span>
        {isSelected && (
          <Icon.Check style={{ color : '#77bb41', width : 45, height : 45 }}/>
        )}
      </div>
    )
  }

  render() {
    const { selectedAudioDeviceId, selectedVideoDeviceId, audioDevices, videoDevices, loading } = this.state;
    const isButtonDisabled = !selectedAudioDeviceId || !selectedVideoDeviceId;

    return (
      <div className={'settings-screen'}>
        <span className={'settings-screen__header-title'}>Media Settings</span>
        {loading ? (
          <div className={'settings-screen__loader-holder'}>
            <Spinner size={80}/>
            <span className={'settings-screen__loader-text'}>Collecting Media Data...</span>
          </div>
        ) : (
          <div className={'settings-screen__content'}>
            <div className={'settings-screen__devices-block'}>
              <p className={'settings-screen__devices-block-title'}>Video Device:</p>
              <div className={'settings-screen__devices-list'}>
                {videoDevices.map(device => this.renderDevice(device, selectedVideoDeviceId, 'selectedVideoDeviceId'))}
              </div>
            </div>
            <div className={'settings-screen__devices-block'}>
              <p className={'settings-screen__devices-block-title'}>Audio Device:</p>
              <div className={'settings-screen__devices-list'}>
                {audioDevices.map(device => this.renderDevice(device, selectedAudioDeviceId, 'selectedAudioDeviceId'))}
              </div>
            </div>
            <Button className='settings-screen__continue-button' title={'Continue'} disabled={isButtonDisabled}
                    onClick={() => this.onContinue(selectedVideoDeviceId, selectedAudioDeviceId)}/>
          </div>
        )}
      </div>
    );
  }
}

export default compose(withRouter)(SettingsScreen)
