import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import React, { Component } from 'react';
import Icon from '../../../../../assets/Icon/Icon';
import { AccessPoint } from '../../../../../model/data/AccessPoint';
import { Image } from '../../../../../model/data/Image';
import { Vendor } from '../../../../../model/data/Vendor';
import moment from 'moment';
import { compose } from '../../../../../model/helpers/compose';
import FileHelper from '../../../../../model/helpers/FileHelper';
import Mutations from '../../../../../model/services/graphql/Mutations';
import { Button, ButtonColors, ButtonTypes } from '../../../../views/button/Button';
import { Camera } from '../../../../views/camera/Camera';
import TextInput from '../../../../views/textInput/TextInput';
import './VendorCode.sass'

type Props = WithApolloClient<{
  vendor: Vendor
  accessPoint: AccessPoint
  onGranted: Function
  onDenied: Function
}>;

type State = {
  isLoading: boolean
  code: string
  base64Image?: string
  image?: Image
};

class VendorCode extends Component <Props, State> {

  state = {
    isLoading   : false,
    code        : '',
    base64Image : undefined,
    image       : undefined
  };

  inputRef = React.createRef<HTMLInputElement>();
  webcamRef: any = React.createRef();

  componentDidMount(): void {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  onChangeInput = (value: string) => {
    this.setState({ code : value })
  };

  takePhoto = async () => {
    const base64 = this.webcamRef.captureImage();
    this.setState({ base64Image : base64 });

    await this.uploadBase64Image(base64)
      .then((response: any) => this.setState({ image : response.data.uploadFile as Image }))
      .catch((e: Error) => console.error(e));
  };

  onSendSelect = async () => {
    const { code, image } = this.state;

    this.setState({ isLoading : true });

    if (image === undefined) {
      this.takePhoto().then(() => this.onSendSelect());
    } else {

      this.openAccessPoint(code, image)
        .then(r =>
          this.setState({ isLoading : false }, () =>
            r.data.openAccessPointWithVendorCode === true ? this.props.onGranted() : this.props.onDenied()))
        .catch(e =>
          this.setState({ isLoading : false }, () =>
            console.error(e)));
    }
  };

  renderVendor = () => {
    const { vendor } = this.props;

    if (vendor.logo && vendor.logo.url) {
      return <img className='vendor-logo' src={vendor.logo!.url} alt=''/>
    } else {
      return <div className='vendor-name'>{vendor.name}</div>
    }
  };

  renderWebcam = () => {
    const { base64Image } = this.state;

    return base64Image
      ? <div className='webcam'><img src={base64Image} alt='webcam'/></div>
      : <Camera containerClassName='webcam' videoClassName='webcam-video' ref={ref => this.webcamRef = ref}/>
  };

  render() {
    const { code, isLoading } = this.state;

    return (
      <div className='vendor-code'>
        <span className='date'>{moment().format('dddd MMM DD, YYYY | h:mm A')}</span>
        <div className='content'>
          <div className='webcam-wrapper'>
            <div className='vendor-content'>
              {this.renderVendor()}
            </div>
            {this.renderWebcam()}
            <button className='capture-button' onClick={() => this.takePhoto()}>
              <Icon.CamCapture style={{ width : '50%', height : 'auto', marginTop: '6px' }}/>
            </button>
          </div>
          <div className='input-wrapper'>
            <div className='title'>Enter your secure access code</div>
            <div className='field-wrapper'>
              <TextInput
                className='field'
                type='password'
                inputRef={this.inputRef}
                name={'code'}
                onChange={this.onChangeInput}
                value={code}
                placeholder={'Enter Code'}
                onKeyDown={this.handleEnter}/>
            </div>
            <Button className='send-button'
                    height={100}
                    type={ButtonTypes.filled}
                    color={ButtonColors.green}
                    disabled={!this.isCodeValid()}
                    loading={isLoading}
                    onClick={this.onSendSelect}>Submit</Button>
          </div>
        </div>
      </div>
    );
  }

  // Utils

  isCodeValid = () => {
    return this.state.code.length > 0;
  };

  handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ([e.keyCode, e.which].includes(13) && this.isCodeValid()) this.onSendSelect();
  };

  // API methods

  uploadBase64Image = (base64Image: string) => {
    const file = FileHelper.dataURLtoFile(base64Image, 'screenshot.jpg');
    const variables = { field : 'file', file : file };

    return this.props.client!.mutate({
        mutation  : Mutations.uploadFile,
        variables : variables
      })
  };

  openAccessPoint = (code: string, image: Image) => {
    const { accessPoint, vendor } = this.props;

    return this.props.client!.mutate({
        mutation  : Mutations.openAccessPointWithVendorCode,
        variables : {
          id        : accessPoint.id,
          companyId : vendor.id,
          code      : code,
          imageId   : image.id
        }
      })
  };
}

export default compose(
  withApollo
)(VendorCode);
