import { ChildDataProps, graphql } from '@apollo/client/react/hoc';
import React, { Component } from 'react';
//@ts-ignore
import { Scrollbars } from 'react-custom-scrollbars-2';
import { RouterProps, withRouter } from 'react-router';
import Icon from '../../../assets/Icon/Icon';
import { Tenant } from '../../../model/data/Tenant';
import { compose } from '../../../model/helpers/compose';
import { AuthControllerProps, withAuthController } from '../../../model/providers/AuthProvider';
import { withIdleController } from '../../../model/providers/IdleProvider/IdleProvider';
import queries from '../../../model/services/graphql/Queries';
import { Button } from '../../views/button/Button';
import { Spinner } from '../../views/spinner/Spinner';
import UserPic from '../../views/userPic/UserPic';
import './PndScreen.sass';

type Response = {
  pndContacts: Tenant[];
};

type Variables = {
  accessPointId: string;
};

type ChildProps = AuthControllerProps & RouterProps & ChildDataProps<{}, Response, Variables>;

type Props = ChildProps & {
  searchQuery: string
  onTenantSelect: Function,
  idleTimeoutController: any,
};

class PndList extends Component<Props> {

  onScroll = () => {
    this.props.idleTimeoutController.start();
  }

  filterTenants = (tenants: Tenant[], searchQuery: string) => {
    if (!searchQuery) return tenants;
    return tenants.filter((item: any) => {
      const info = (item.name + (item.unit ? item.unit.name : ''));
      return info.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
    });
  };

  renderTenant = (tenant: Tenant, index: number) => {
    let background = index % 2 ? 'linear-gradient(#06335DCC, #06335D33)' : '#03192980';

    return (
      <tr className='pnd-list__table-row' key={index} style={{ background : `${background}` }}>
        <td>
          <div className="pnd-list__table-row__item-user">
            <UserPic user={tenant} size={80}/>
            <span className='pnd-list__table-row__item-initials'>{tenant.name}</span>
          </div>
        </td>
        <td>
          <span className='pnd-list__table-row__item-unit'>{tenant.unit || '-'}</span>
        </td>
        <td>
          <Button className='pnd-list__table-row__connect-button' onClick={() => this.props.onTenantSelect(tenant)}>
            <Icon.Bell style={{ marginRight : 20, width: 32, height: 32 }}/>
            <span style={{ fontWeight : 600, paddingBottom: 1 }}>Call</span>
          </Button>
        </td>
      </tr>
    )
  };

  render() {
    const { pndContacts, loading } = this.props.data;
    const { searchQuery } = this.props;
    const filtered = this.filterTenants(pndContacts || [], searchQuery);

    if (loading) {
      return <div className='pnd-list'>
        <div className='spinner-wrapper'>
          <Spinner size={160}/>
        </div>
      </div>
    }

    return (
      <Scrollbars className='pnd-list' onScroll={this.onScroll}>
        {filtered.length > 0 ? <>
          <table className='pnd-list__table'>
            <thead>
            <tr className='pnd-list__header'>
              <th className='pnd-list__header-column pnd-list__header-column-name'>RESIDENT</th>
              <th className='pnd-list__header-column pnd-list__header-column-unit'>UNIT</th>
              <th className='pnd-list__header-column pnd-list__header-column-action'/>
            </tr>
            </thead>
            <tbody>
            {filtered.map(this.renderTenant)}
            </tbody>
          </table>
        </> : <>
          <div className='pnd-list__placeholder'>
            <Icon.CloudSad style={{ width : 400, height : 'auto' }}/>
            <span className='pnd-list__placeholder-text'>It seems like no one's here</span>
          </div>
        </>
        }
      </Scrollbars>
    )
  }
}

export default compose(
  withRouter,
  withAuthController,
  withIdleController,
  graphql<Props, Response, Variables, ChildProps>(queries.pndContacts, {
    options : props => ({
      fetchPolicy : 'cache-and-network',
      variables   : {
        accessPointId : props.authController.cloudScreen!.accessPoint.id
      }
    })
  })
)(PndList);
