import * as React from 'react';
import { WithApolloClient } from '@apollo/client/react/hoc';
import Icon from '../../../assets/Icon/Icon';
import { compose } from '../../../model/helpers/compose';
import { AuthControllerProps, withAuthController } from '../../../model/providers/AuthProvider';
import { IdleControllerProps, withIdleController } from '../../../model/providers/IdleProvider/IdleProvider';
import { Spinner } from '../../views/spinner/Spinner';
import './MaintenanceScreen.sass';
import { createLogger } from '../../../model/helpers/Logger';

const logger = createLogger('[MaintenanceScreen]');

const FETCH_INTERVAL = 30 * 1000;

type Props = WithApolloClient<{} & AuthControllerProps & IdleControllerProps>

class MaintenanceScreen extends React.Component<Props> {

  interval: any;

  componentDidMount() {
    this.props.idleTimeoutController.stop();
    this.start();
  }

  componentWillUnmount(): void {
    this.props.idleTimeoutController.start();
    this.stop();
  }

  start = () => {
    this.stop();
    this.interval = setInterval(this.fetchCloudScreen, FETCH_INTERVAL);
  };

  stop = () => {
    clearTimeout(this.interval);
  };

  fetchCloudScreen = () => {
    logger.i('>>>> MaintenanceScreen fetch');
    this.props.authController.fetchCloudScreen();
  };

  render() {
    return (
      <div className={'maintenance'}>
        <div className={'maintenance-content'}>
          <Icon.CloudSmile style={{ width : 600, height: 400 }}/>
          <div className='maintenance__text'>
            Your Cloud Screen is getting even better and will become available shortly!
          </div>
          <Spinner size={150}/>
        </div>
      </div>
    );
  }
}

export default compose(
  withAuthController,
  withIdleController,
)(MaintenanceScreen);
