import * as React from 'react';
import { RefObject } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import Video, { Participant, RemoteParticipant, Room } from 'twilio-video';
import { RemoteTrackPublication } from 'twilio-video/tsdef/RemoteTrackPublication';
import { RemoteTrack } from 'twilio-video/tsdef/types';
import Icon from '../../../../../assets/Icon/Icon';
import Sound from '../../../../../assets/sounds';
import { AccessPoint } from '../../../../../model/data/AccessPoint';
import { CallCaller } from '../../../../../model/data/CallCaller';
import { CallRecipient, CallRecipientType } from '../../../../../model/data/CallRecipient';
import { Connection } from '../../../../../model/data/Connection';
import { compose } from '../../../../../model/helpers/compose';
import { getMediaPath } from '../../../../../model/helpers/ElectronUtils';
import { createLogger } from '../../../../../model/helpers/Logger';
import { AuthControllerProps, withAuthController } from '../../../../../model/providers/AuthProvider';
import { withWebSocketClient, WithWebSocketProps } from '../../../../../model/providers/WebSocketProvider';
import AppEventDispatcher, { AppEventType } from '../../../../../model/services/AppEventDispatcher';
import Mutations from '../../../../../model/services/graphql/Mutations';
import MediaStreamService from '../../../../../model/services/MediaStreamService';
import { Button, ButtonColors } from '../../../../views/button/Button';
import UserPic from '../../../../views/userPic/UserPic';
import './CallView.sass';

const logger = createLogger('[CallView]');

const attachTrack = (track: RemoteTrack | any, container: RefObject<HTMLMediaElement>, audioEnabled: Boolean = true) => {
  const tracksContainer = container.current;

  if (tracksContainer && track) {
    track.attach(tracksContainer);
  }
};

const detachTrack = (track: RemoteTrack | any, containerRef: RefObject<HTMLMediaElement>) => {
  const trackContainer = containerRef.current;

  if (trackContainer && track) {
    track.detach(trackContainer);
  }
};

type Props = WithApolloClient<{
  accessPoint: AccessPoint,
  caller: CallCaller,
  recipient: CallRecipient,
  onCancelled: Function
  onAccessGranted: Function
  onAccessDenied: Function
}> & WithWebSocketProps & AuthControllerProps;

type State = {
  isActive: boolean,
  remoteVideoEnabled: boolean,
  duration: string
};

class CallView extends React.Component<Props, State> {

  _isMounted = false;
  _connectTimeout: any;
  room: Room | null = null;
  connecttion: Connection | null = null;
  ringtoneAudio: HTMLAudioElement = new Audio(getMediaPath(Sound.callRingtone));
  remoteVideoRef = React.createRef<any>();
  remoteAudioRef = React.createRef<any>();
  localVideoRef: any = React.createRef();
  startTime: number = 0;
  timeoutId: any = null;
  mediaStreamService: MediaStreamService | null = null;
  mediaStream: MediaStream | null = null;
  streamTracks: MediaStreamTrack[] = [];

  state = {
    isActive           : false,
    remoteVideoEnabled : false,
    duration           : '',
  };

  componentDidMount() {
    this._isMounted = true;

    AppEventDispatcher.addListener(AppEventType.ACCESS_GRANTED, this.onAccessGranted);
    AppEventDispatcher.addListener(AppEventType.ACCESS_DENIED, this.onAccessDenied);
    this.startMediaStream();
    this.initConnection();
    //@ts-ignore
    window.audioRef = this.remoteAudioRef;
  }

  componentWillUnmount(): void {
    this._isMounted = false;

    clearTimeout(this._connectTimeout);

    AppEventDispatcher.removeListener(AppEventType.ACCESS_GRANTED);
    AppEventDispatcher.removeListener(AppEventType.ACCESS_DENIED);
    this.stopMediaStream();
  }

  startMediaStream = async () => {
    this.mediaStreamService = MediaStreamService.getInstance();
    const mediaStream = await this.mediaStreamService.getStream();
    logger.i('got media stream', mediaStream)
    if (mediaStream) {
      this.streamTracks = mediaStream.getTracks();
      logger.i('got media TRACKS', mediaStream.getTracks())
      this.localVideoRef.srcObject = mediaStream;
      this.localVideoRef.play();
    }
  };

  stopMediaStream = () => {
    if (this.streamTracks.length > 0 && this.mediaStreamService) {
      this.localVideoRef.pause();
      this.streamTracks = [];
      this.mediaStreamService.stopStream(true);
    }
  };

  onAccessGranted = () => {
    this.disconnect();
    this.props.onAccessGranted()
  };

  onAccessDenied = () => {
    this.disconnect();
    this.props.onAccessDenied();
  };

  onCancelled = () => {
    this.cancelConnection();
    this.props.onCancelled();
  };

  initConnection(): void {
    const { caller, recipient, accessPoint } = this.props;

    const callerParams = {
      name    : caller.name,
      imageId : caller.image ? caller.image!.id : null,
    };

    switch (recipient.type) {
      case CallRecipientType.tenant:
        this.connectTenant({
          accessPointId : accessPoint.id,
          contactId     : recipient.user.id,
          contactType   : recipient.user.type,
          caller        : callerParams,
        });
        break;

      case CallRecipientType.leasing:
        this.connectLeasing({
          accessPointId : accessPoint.id,
          caller        : {
            ...callerParams,
            deviceToken : ' ',
          },
        });
        break;

      case CallRecipientType.manager:
        this.connectManager({
          accessPointId : accessPoint.id,
          caller        : {
            ...callerParams,
            deviceToken : ' ',
          },
        });
        break;
    }

    this.ringtoneAudio.loop = true;
    this.ringtoneAudio.play();
  };

  connectTenant(data: any): void {
    this.props.client!.mutate({ mutation : Mutations.initConnectionWithContact, variables : { data } })
      .then((resp: any) => this.connect(resp.data.initConnectionWithContact))
      .catch((err) => logger.e('InitConnection error:', err));
  };

  connectManager(vars: any): void {
    this.props.client!.mutate({ mutation : Mutations.initConnectionWithPropertyManager, variables : vars })
      .then((resp: any) => this.connect(resp.data.initConnectionWithPropertyManager))
      .catch((err) => logger.e('InitConnection error:', err));
  };

  connectLeasing(vars: any): void {
    this.props.client!.mutate({ mutation : Mutations.initConnectionWithLeasingManager, variables : vars })
      .then((resp: any) => this.connect(resp.data.initConnectionWithLeasingManager))
      .catch((err) => logger.e('InitConnection error:', err));
  };

  cancelConnection = () => {
    if (this.connecttion) {
      this.props.client!.mutate({
        mutation  : Mutations.cancelConnection,
        variables : {
          id : this.connecttion.id,
        },
      })
        .then(() => logger.i('CancelConnection success'))
        .catch((err) => logger.e('CancelConnection error:', err));
    }

    this.setState({
      remoteVideoEnabled : false,
      isActive           : false,
    });

    this.disconnect();
  };

  connect = async (data: any) => {
    logger.i('connect called', data);
    if (!this._isMounted) {
      return
    }
    if (!this.streamTracks.length) {
      logger.i('CONNECT: No tracks, trying again in 2000ms');
      this._connectTimeout = setTimeout(() => {
        this.connect(data);
      }, 2000);
      return;
    }
    const connection = data.connection as Connection;
    const token = data.token as string;
    logger.i('Twilio connecting');

    Video.connect(token, {
      name     : connection.id,
      tracks   : [...this.streamTracks],
      logLevel : 'debug',
    })
      .then((room: Room) => this.onRoomConnect(room))
      .catch((err) => logger.e('Could not connect: ' + err.message));

    this.connecttion = connection;
  };

  disconnect = () => {
    this.timeoutId && clearInterval(this.timeoutId);
    this.ringtoneAudio.pause();
    this.connecttion = null;

    if (this.room) {
      this.room.removeAllListeners();
      this.room.disconnect();
      this.room = null;
    }
  };

  onRoomConnect = (room: Room) => {
    logger.i('Connect to room', room);
    if (!this._isMounted) {
      this.disconnect();
    } else {
      this.room = room;
      this.room.on('participantConnected', () => this.onParticipantConnected());
      this.room.on('participantDisconnected', () => this.onParticipantDisconnected());
      this.room.on('trackSubscribed', (track: RemoteTrack, publication: RemoteTrackPublication, participant: RemoteParticipant) => this.onTrackSubscribed(track, participant));
      this.room.on('trackUnsubscribed', (track: RemoteTrack, publication: RemoteTrackPublication, participant: RemoteParticipant) => this.onTrackUnsubscribed(track, participant));
      this.room.on('trackEnabled', (publication: RemoteTrackPublication, participant: RemoteParticipant) => this.onTrackEnabled(publication));
      this.room.on('trackDisabled', (publication: RemoteTrackPublication, participant: RemoteParticipant) => this.onTrackDisabled(publication));
    }
  };

  onParticipantConnected = () => {
    logger.i('on participant connected');
    this.ringtoneAudio.pause();
    this.startTime = new Date().getTime();
    this.timeoutId = setInterval(() => {
      const endTime = new Date().getTime();
      const interval = endTime - this.startTime;
      this.setState({
        duration : new Date(interval).toISOString().substr(11, 8),
      })
    }, 1000);

    this.setState({ isActive : true });
  };

  onParticipantDisconnected = () => {
    //this.disconnect();
    //this.cancelConnection()
  };

  onTrackSubscribed = (track: RemoteTrack, participant: Participant) => {
    if (track.kind === 'video') {
      this.setState(
        { remoteVideoEnabled : track.kind === 'video' && track.isEnabled },
        () => attachTrack(track, this.remoteVideoRef),
      );
    } else if (track.kind === 'audio') {
      attachTrack(track, this.remoteAudioRef);

      const audioCtx = new AudioContext();
      const source = audioCtx.createMediaElementSource(this.remoteAudioRef.current);

      const gainNode = audioCtx.createGain();
      gainNode.gain.value = 5;
      source.connect(gainNode);

      gainNode.connect(audioCtx.destination);
    }
  };

  onTrackUnsubscribed = (track: RemoteTrack, participant: Participant) => {
    if (track.kind === 'video') {
      this.setState(
        { remoteVideoEnabled : track.kind === 'video' ? false : this.state.remoteVideoEnabled },
        () => detachTrack(track, this.remoteVideoRef),
      );
    } else {
      detachTrack(track, this.remoteAudioRef);
    }
  };

  onTrackEnabled = (track: RemoteTrackPublication) => this.setState(
    { remoteVideoEnabled : track.kind === 'video' ? true : this.state.remoteVideoEnabled },
    () => attachTrack(track, track.kind === 'video' ? this.remoteVideoRef : this.remoteAudioRef),
  );

  onTrackDisabled = (track: RemoteTrackPublication) => this.setState(
    { remoteVideoEnabled : track.kind === 'video' ? false : this.state.remoteVideoEnabled },
    () => detachTrack(track, track.kind === 'video' ? this.remoteVideoRef : this.remoteAudioRef),
  );

  render() {
    const { recipient } = this.props;
    const { remoteVideoEnabled, duration, isActive } = this.state;

    const marginTop = remoteVideoEnabled ? '0px' : '300px';
    const picSize = remoteVideoEnabled ? 0 : 360;
    const info = remoteVideoEnabled ? '' : isActive ? 'Talking to' : 'Waiting for connection with';

    return (
      <>
        <div className="call-view__remote-view"
             style={remoteVideoEnabled ? {} : { opacity : 0, visibility : 'hidden' }}>
          <video ref={this.remoteVideoRef} autoPlay={true} muted={true}/>
        </div>
        <audio ref={this.remoteAudioRef} style={{ visibility : 'hidden' }} autoPlay={true} muted={false}/>
        <div className="call-view__content">
          <div className="calling-view">
            <div className="calling-view__tenant" style={{ marginTop : marginTop }}>
              <UserPic className="calling-view__tenant-image" user={recipient.user} size={picSize}/>
              <div className="calling-view__tenant-message">{info}</div>
              <div className="calling-view__tenant-name">{recipient.user.name}</div>
              <div className="calling-view__tenant-message calling-view__duration">{duration}</div>
            </div>
            <Button className="calling-view__decline-button" onClick={this.onCancelled} height={100}
                    color={ButtonColors.red}>
              <Icon.Decline style={{ width : '36px', height : 'auto', marginRight : '20px' }}/>Hang Up
            </Button>
          </div>
          <div className="call-view__local-view">
            <video ref={(stream) => this.localVideoRef = stream} muted={true}/>
          </div>
        </div>
      </>
    )
  }
}

export default compose(
  withApollo,
  withWebSocketClient,
  withAuthController,
)(CallView);
