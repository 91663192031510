import { ApolloError } from '@apollo/client';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import * as React from 'react';
import './RegistrationScreen.sass';
import { compose } from '../../../model/helpers/compose';
import { createLogger } from '../../../model/helpers/Logger';
import Mutations from '../../../model/services/graphql/Mutations';
import { AuthControllerProps, withAuthController } from '../../../model/providers/AuthProvider';
import { Button } from '../../views/button/Button';
import TextInput from '../../views/textInput/TextInput';
//@ts-ignore
import { v4 as uuidv4 } from 'uuid';

const logger = createLogger('[RegistrationScreen]');

type Props = WithApolloClient<{} & AuthControllerProps>

class RegistrationScreen extends React.Component<Props> {

  state = {
    name    : '',
    loading : false,
  };

  onChange = (value: string) => {
    this.setState({
      name : value
    })
  };

  onSubmit = () => {
    const { name } = this.state;

    this.setState({ loading : true });
    this.props.client!.mutate({
      mutation  : Mutations.registerCloudScreen,
      variables : {
        name,
        deviceToken : uuidv4(),
        isSandbox   : true,
      }
    })
      .then((res: any) => this.onRegistered(res))
      .catch((err: ApolloError) => {
        logger.e('Register error', err)
      })
  };

  onRegistered = (res: any) => {
    const { authController } = this.props;
    this.setState({ loading : false });

    if (res.data.registerCloudScreen) {
      authController.login({
        cloudScreen : res.data.registerCloudScreen,
      });
    }
  };

  render() {
    return (
      <div className={'registration-screen'}>
        <div className={'registration-screen__holder'}>
          <p className='registration-screen__label'>Enter CloudScreen Name:</p>
          <TextInput name={'name'} onChange={this.onChange} value={this.state.name}
                     placeholder={'e.g. Broadway Entrance'} onEnter={this.onSubmit}/>
          <Button className='registration-screen__button' title={'Register'} onClick={this.onSubmit} height={120} loading={this.state.loading}/>
        </div>
      </div>
    );
  }
}

export default compose(
  withApollo,
  withAuthController,
)(RegistrationScreen);
