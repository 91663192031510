import * as React from 'react';
import { ReactComponent as ArrowLeft } from './arrow-left.svg'
import { ReactComponent as ArrowRight } from './arrow-right.svg'
import { ReactComponent as Check } from './check.svg'
import { ReactComponent as Error } from './error.svg'
import InviteIcon from './invite/InviteIcon';
import { ReactComponent as Pencil } from './pencil.svg'
import { ReactComponent as Plus } from './plus.svg'
import { ReactComponent as Trash } from './trash.svg'
import { ReactComponent as User } from './user.svg'
import { ReactComponent as LoaderOval } from './loader-oval.svg'
import { ReactComponent as Bell } from './bell.svg'
import { ReactComponent as Back } from './back.svg'
import { ReactComponent as CloudGeo} from './cloud-geo.svg'
import { ReactComponent as Person } from './person.svg'
import { ReactComponent as Mic } from './mic.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as AccessCode } from './access-code-icon.svg'
import { ReactComponent as Leasing } from './leasing-icon.svg'
import { ReactComponent as Vendors } from './vendors-icon.svg'
import { ReactComponent as Decline } from './decline-icon.svg'
import { ReactComponent as Cloud } from './cloud.svg'
import { ReactComponent as CloudSmile } from './cloud-smile.svg'
import { ReactComponent as CloudSad } from './cloud-sad.svg'
import { ReactComponent as CodeResident } from './code/code-resident.svg'
import { ReactComponent as CodeGuest } from './code/code-guest.svg'
import { ReactComponent as Warning } from './warning.svg'
import { ReactComponent as CamCapture } from './cam_capture_button.svg'
import { ReactComponent as Close } from './close_icon.svg'
import { ReactComponent as DialRemove } from './dial-remove.svg'
import { ReactComponent as DialDelete } from './dial-delete.svg'
import { ReactComponent as Video } from './video-icon.svg'
import { ReactComponent as Smile } from './smile.svg'

const Icon = {
  Invite: InviteIcon,
  ArrowBack : () => <ArrowLeft style={{ width : 14, height : 14 }}/>,
  ArrowLeft,
  ArrowRight,
  Check,
  Error,
  Pencil,
  Plus,
  Trash,
  User,
  LoaderOval,
  Bell,
  Back,
  CloudGeo,
  Person,
  Search,
  Mic,
  AccessCode,
  Leasing,
  Vendors,
  Decline,
  Cloud,
  CloudSmile,
  CloudSad,
  CodeResident,
  CodeGuest,
  Warning,
  CamCapture,
  Close,
  DialRemove,
  DialDelete,
  Video,
  Smile
};

export default Icon