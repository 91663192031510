import * as React from 'react';
import { withApollo } from '@apollo/client/react/hoc';

import { RouterProps, withRouter } from 'react-router';
import Icon from '../../../assets/Icon/Icon';
import { CallRecipient, CallRecipientType } from '../../../model/data/CallRecipient';
import { PropertyType } from '../../../model/data/Property';
import { compose } from '../../../model/helpers/compose';
import { parseAddress } from '../../../model/helpers/Data';
import { AuthControllerProps, withAuthController } from '../../../model/providers/AuthProvider';
import { CallProviderProps, withCallController } from '../../../model/providers/CallProvider';
import AppEventDispatcher, { AppEventType } from '../../../model/services/AppEventDispatcher';
import { AppRoute, urlTo } from '../../app/AppRoute';
import { Button, ButtonColors, ButtonTypes } from '../../views/button/Button';
import Modal from '../../views/modal/Modal';
import InviteScreen from '../invite/InviteScreen';
import './HomeScreen.sass';
import { InviteButton } from './views/inviteButton/InviteButton';
import WelcomeScreen from './welcome/WelcomeScreen';

enum ButtonType {
  manager = 0,
  vendors = 1,
  code = 2,
  leasing = 3
}

type State = {
  inviteType?: string,
  doorOpened: boolean
}

type Props = AuthControllerProps & RouterProps & { callController: CallProviderProps };

class HomeScreen extends React.Component<Props, State> {

  historyListener: any;

  state = {
    inviteType : undefined,
    doorOpened : false
  };

  componentDidMount(): void {
    this.historyListener = this.props.history.listen(() =>
      this.setState({ inviteType : undefined }));

    AppEventDispatcher.addListener(AppEventType.DOOR_OPENED, this.onDoorOpened);
  }

  componentWillUnmount() {
    this.historyListener();
  }

  redirectTo = (route: string, data: object = {}) => this.props.history.push(urlTo(route, data));
  onPndAction = () => this.redirectTo(AppRoute.pnd, { id : this.props.authController.accessPoint!.id });
  onCodeAction = () => this.redirectTo(AppRoute.code);
  onLockoutAction = () => this.redirectTo(AppRoute.emergency);
  onSettingsAction = () => this.redirectTo(AppRoute.settings);
  onVendorsAction = () => this.redirectTo(AppRoute.vendors);
  onInviteAction = (action: string) => {
    // this.props.history.push(AppRoute.settings);
    this.setState({ inviteType : action });
  }

  onDoorOpened = () => {
    const { inviteType } = this.state;
    const recipient = this.props.callController.recipient;
    const canShow = inviteType === undefined && recipient === undefined;
    this.setState({ doorOpened : canShow })
  };

  onDoorClosed = () => {
    this.setState({ doorOpened : false })
  };

  onManagerAction = () => {
    const user = { firstName : 'Property', lastName : 'Manager' };
    const recipient = new CallRecipient(CallRecipientType.manager, user);
    this.props.callController.initiateCall(recipient);
  };

  onLeasingAction = () => {
    const user = { firstName : 'Leasing', lastName : 'Office' };
    const recipient = new CallRecipient(CallRecipientType.leasing, user);
    this.props.callController.initiateCall(recipient);
  };

  renderButton = (type: ButtonType, action: Function) => {
    const iconSize = {
      [ButtonType.code]    : { width : 32, height : 32 },
      [ButtonType.leasing] : { width : 32, height : 32 },
      [ButtonType.vendors] : { width : 36, height : 34 },
      [ButtonType.manager] : { width : 30, height : 30 }
    }
    const iconStyle = { marginRight : '20px', ...iconSize[type] as object };
    const uiData = [
      { icon : <Icon.Person style={iconStyle}/>, title : 'Property Manager' },
      { icon : <Icon.Vendors style={iconStyle}/>, title : 'Vendor\'s Portal' },
      { icon : <Icon.AccessCode style={iconStyle}/>, title : 'Access Code' },
      { icon : <Icon.Leasing style={iconStyle}/>, title : 'Leasing Office' }
    ];

    return (
      <Button
        className='content-area__button'
        height={90}
        type={ButtonTypes.outlined}
        color={ButtonColors.white}
        onClick={() => action()}
      >
        {uiData[type].icon}
        {uiData[type].title}
      </Button>
    )
  };

  render() {
    const { cloudScreen, accessPoint } = this.props.authController;
    const { inviteType, doorOpened } = this.state;

    if (!cloudScreen || !accessPoint) return null;

    const { address, image, logo, type } = accessPoint.property;
    const isOffice = type === PropertyType.office;
    const logoURL = logo && logo.url;
    const hasManager = cloudScreen.canContactManager;
    const hasLeasing = cloudScreen.canContactLeasingManager;
    const hasVendors = accessPoint.allowVendors;
    const hasLogo = logoURL !== null;

    return (
      <div className='home-screen' style={{ backgroundImage : `url(${image && image.url})` }}>
        <div className='content-area-wrapper'>
          <div className='content-area'>
            <div className='welcome-to-label'>welcome to</div>
            {hasLogo && <div className='logo'><img src={logo && logo.url} alt=''/></div>}
            {!hasLogo && <div className='logoTitle'>{accessPoint.property.name}</div>}
            <Button
              className={'content-area__main-button'}
              height={120}
              type={ButtonTypes.filled}
              color={ButtonColors.green}
              onClick={isOffice ? this.onManagerAction : this.onPndAction}>
              {isOffice ? 'Contact Manager' : 'Property Directory'}
            </Button>
            <div className='content-area__buttons-wrapper'>
              {(isOffice ? false : hasManager) && this.renderButton(ButtonType.manager, this.onManagerAction)}
              {hasVendors && this.renderButton(ButtonType.vendors, this.onVendorsAction)}
              {this.renderButton(ButtonType.code, this.onCodeAction)}
              {hasLeasing && this.renderButton(ButtonType.leasing, this.onLeasingAction)}
            </div>
            <div className={'content-area__address'}>{parseAddress(address)}</div>
          </div>
        </div>
        <div className={'footer-area'}>
          <div className={'footer-area__info'}>
            <div className={'footer-area__info-text'}>
              <span style={{ color : '#f7be3b', fontWeight : 600 }}>Frequent Visitor?</span>
              <span>Download Free CloudKeyz App for keyless entry.</span>
            </div>
            <div className={'footer-area__logo'}>
              <Icon.CloudGeo style={{ width : 42, height : 36 }}/>
              <span className='footer-area__logo-text'>Powered by CloudKeyz®</span>
            </div>
          </div>
          <div className={'footer-area__invite'}>
            <div className={'footer-area__invite-text'}>
              <Icon.Invite.GetApp style={{ width : 22, height : 28 }}/>
              <span style={{ marginLeft : 10 }}>Get Free App:</span>
            </div>
            <div className={'footer-area__invite-buttons'}>
              <InviteButton onClick={() => this.onInviteAction('Apple')}>
                <Icon.Invite.AppStore style={{ width : '100%', height : '100%' }}/>
              </InviteButton>
              <InviteButton onClick={() => this.onInviteAction('Google')}>
                <Icon.Invite.GooglePlay style={{ width : '100%', height : '100%' }}/>
              </InviteButton>
            </div>
          </div>
        </div>
        {inviteType && <Modal>
          <InviteScreen type={inviteType}
                        onBack={() => this.setState({ inviteType : undefined })}
                        onLockout={() => this.setState({ inviteType : undefined }, () => this.onLockoutAction())}
                        onSettings={() => this.setState({ inviteType : undefined }, () => this.onSettingsAction())}
          />
        </Modal>}
        {doorOpened && <Modal>
          <WelcomeScreen accessPoint={accessPoint} onComplete={() => this.onDoorClosed()}/>
        </Modal>}
      </div>
    )
  }
}

export default compose(
  withRouter,
  withApollo,
  withCallController,
  withAuthController,
)(HomeScreen);
