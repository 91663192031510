class DataLoader {

  static loadImage = (url: string) => {
    return new Promise((resolve, reject) => {
      if (!url) reject();

      const image = new Image();
      image.onload = resolve;
      image.onerror = reject;
      image.src = url;
    })
  };
}

export default DataLoader;