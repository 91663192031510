import React, { Component } from 'react';
import { Camera } from '../../../../../views/camera/Camera';
import './CallerPhotoView.sass';

type Props = {
  onComplete: Function
};

class CallerPhotoView extends Component<Props> {

  intervalId = 0;
  timeoutId = 0;
  webcamRef: any = React.createRef();

  state = {
    countdown   : 3,
    base64Image : null,
  };

  componentWillUnmount() {
    this.intervalId && clearInterval(this.intervalId);
    this.timeoutId && clearTimeout(this.timeoutId);
  }

  componentDidMount() {
    this.intervalId = window.setInterval(this.onTimerAction, 1000);
  }

  onComplete = () => {
    this.timeoutId = window.setTimeout(() => this.props.onComplete(this.state.base64Image), 500);
  };

  onTimerAction = () => {
    const count = this.state.countdown - 1;

    if (count === 0) {
      clearInterval(this.intervalId);
      this.setState({ base64Image : this.webcamRef.captureImage() }, () => {
        setTimeout(() => {
          this.onComplete();
        }, 500)
      });
    } else {
      this.setState({ countdown : count });
    }
  };

  render() {
    const { base64Image } = this.state;

    return (
      <div className='content-photo'>
        {
          base64Image == null
            ? this.renderContent()
            : this.renderSnapshot(base64Image)
        }
      </div>
    );
  }

  renderContent = () => {
    return (
      <>
        <Camera ref={ref => this.webcamRef = ref} containerClassName='webcam' audioMuted={true}
                stopStreamOnUnmount={true} videoClassName='webcam-video'/>
        <div className='content-photo__border'>
          <div className={'top-left'}/>
          <div className={'top-right'}/>
          <div className={'bottom-right'}/>
          <div className={'bottom-left'}/>
        </div>
        <div className='content-photo__container'>
          <div className='content-photo__title'>Smile for the camera!</div>
          <div className='content-photo__subtitle'>We are taking a picture to show who's calling.</div>
          <div className='content-photo__counter'>
            <div className={'content-photo__counter-text'}>{this.state.countdown}</div>
          </div>
        </div>
      </>
    );
  };

  renderSnapshot = (base64Image: string) => {
    const backgroundImage = `url(${base64Image})`;
    return (
      <div className={'content-photo__snapshot-wrapper'} style={{ backgroundImage, transform: "scaleX(-1)" }}/>
    )
  };
}

export default CallerPhotoView;
