import moment from 'moment';
import React, { Component } from 'react';
import Icon from '../../../../../assets/Icon/Icon';
import { EmergencyItem } from '../../EmergencyScreen';
import './EmergencyList.sass'
import EmergencyListItem from './EmergencyListItem';

type Props = {
  items: EmergencyItem[],
  onSelect: (vendor: EmergencyItem) => void
};

class EmergencyList extends Component <Props> {

  render() {
    const { onSelect, items } = this.props;

    return (
      <div className='emergency-list'>
        <div className='emergency-list__date'>{moment().format('dddd MMM DD, YYYY | h:mm A')}</div>
        <div className='emergency-list__content'>
          <div className='emergency-list__title'>Select your department:</div>
          <div className='list' key={items.length}>
            {items.map((value, index) =>
              <EmergencyListItem key={index} item={value} onSelect={() => onSelect(value)}/>)}
          </div>
        </div>
        <div className='emergency-list-footer'>
          <Icon.Video className='emergency-list-footer__video-icon'/>
          <div className='emergency-list-footer__text'>This access event is being recorded, emergency personnel only.</div>
        </div>
      </div>
    );
  }
}

export default EmergencyList;
