import { Image } from './Image';
import { Address } from './Address';
import { Unit } from './Unit';

export enum PropertyType {
  mf = "MF",
  gc = "GC",
  office = "OFFICE"
}

export type Property = {
  id: string,
  type: PropertyType
  name?: string
  image?: Image
  logo?: Image,
  address: Address
  units: [Unit]
}