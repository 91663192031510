import React, { Component } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { RouterProps, withRouter } from 'react-router';
import Icon from '../../../assets/Icon/Icon';
import { BATCH_SIZE } from '../../../model/Constants';
import { Vendor } from '../../../model/data/Vendor';
import { compose } from '../../../model/helpers/compose';
import { createLogger } from '../../../model/helpers/Logger';
import { AuthControllerProps, withAuthController } from '../../../model/providers/AuthProvider';
import queries from '../../../model/services/graphql/Queries';
import { BackButton } from '../../views/backButton/BackButton';
import Modal from '../../views/modal/Modal';
import './VendorsScreen.sass'
import { Spinner, SpinnerType } from '../../views/spinner/Spinner';
import VendorCode from './views/code/VendorCode';
import VendorDenied from './views/denied/VendorDenied';
import VendorGranted from './views/granted/VendorGranted';
import VendorsList from './views/list/VendorsList';

const logger = createLogger('[VendorsScreen]');

enum ViewType {
  loading = 'loading',
  list = 'list',
  code = 'code',
  granted = 'granted',
  denied = 'denied'
}

type Props = WithApolloClient<{
  data?: any,
  history?: any
}> & RouterProps & AuthControllerProps;

type State = {
  type: ViewType
  isLoading: boolean,
  vendors: Vendor[],
  vendor: Vendor | undefined,
  page: number,
  hasNextPage: boolean,
}

class VendorsScreen extends Component<Props, State> {

  state = {
    type        : ViewType.loading,
    isLoading   : false,
    vendors     : [],
    vendor      : undefined,
    page        : 0,
    hasNextPage : true,
  };

  componentDidMount(): void {
    this.fetchVendors(1);
  }

  onBackAction = () => {
    this.state.type === ViewType.code ?
      this.setState({ type : ViewType.list, vendor : undefined }) :
      this.props.history.goBack();
  };

  onListFetch = () => this.fetchVendors(this.state.page + 1);
  onVendorSelect = (v: Vendor) => this.setState({ type : ViewType.code, vendor : v });
  onCodeGranted = () => this.setState({ type : ViewType.granted });
  onCodeDenied = () => this.setState({ type : ViewType.denied });
  onRetryAction = () => this.setState({ type : ViewType.code});
  onCompleteAction = () => this.props.history.goBack();

  renderContent = () => {
    const { type, vendors, vendor } = this.state;
    const { accessPoint } = this.props.authController;

    switch (type) {
      case ViewType.loading:
        return <>
          <div className='spinner-wrapper'>
            <Spinner type={SpinnerType.black} size={50}/>
          </div>
        </>;

      case ViewType.list:
        return <VendorsList
          vendors={vendors}
          onSelect={this.onVendorSelect}
          onFetch={this.onListFetch}/>;

      case ViewType.code:
        return <VendorCode
          vendor={vendor!}
          accessPoint={accessPoint!}
          onGranted={this.onCodeGranted}
          onDenied={this.onCodeDenied}/>;

      case ViewType.granted:
        return <Modal>
          <VendorGranted
            accessPoint={accessPoint!}
            onComplete={this.onCompleteAction}/>
        </Modal>;

      case ViewType.denied:
        return <Modal>
          <VendorDenied
            onRetry={this.onRetryAction}
            onComplete={this.onCompleteAction}/>
        </Modal>;
    }
  };

  render() {
    return (
      <div className="vendors-screen">
        <div className='vendors-screen__header'>
          <div className='vendors-screen__back-button__wrapper'>
            <BackButton onClick={this.onBackAction}/>
          </div>
          <div className='vendors-screen__title'>Secure Vendor Access Only</div>
          <div className='vendors-screen__subtitle'>
            <Icon.Warning className='icon'/>
            Authorized Personnel Only
          </div>
        </div>
        <div className='vendors-screen__content-wrapper'>
          <div className='vendors-screen__content'>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }

  fetchVendors = (page: number) => {
    if (this.state.isLoading ||
      !this.state.hasNextPage ||
      this.state.page === page) return;

    const { accessPoint } = this.props.authController;

    this.setState({ isLoading : true });
    this.props.client!.query({
        fetchPolicy : 'network-only',
        query       : queries.vendors,
        variables   : {
          propertyId : accessPoint!.property.id,
          limit      : BATCH_SIZE,
          offset     : (page - 1) * BATCH_SIZE
        }
      })
      .then((resp: any) => {
        const items = resp.data.vendors.edges.map((item: any): Vendor => item.node);

        this.setState({
          type        : ViewType.list,
          isLoading   : false,
          page        : page,
          vendors     : (this.state.vendors as Vendor[]).concat(items),
          hasNextPage : items.length === BATCH_SIZE
        });
      })
      .catch((e) =>
        this.setState({ isLoading : false }, () => logger.e(e)))
  };

}

export default compose(
  withRouter,
  withApollo,
  withAuthController
)(VendorsScreen);
