const u = (r: string) => (process.env.REACT_APP_BASE_PATH || '/') + r;

export const AppRoute = {
  login       : u('login'),
  pending     : u('pending'),
  settings    : u('settings'),
  home        : u('home'),
  pnd         : u('pnd/:id'),
  code        : u('code'),
  vendors     : u('vendors'),
  emergency   : u('emergency'),
  maintenance : u('maintenance'),
};

export const urlTo = (route: string, params: any = {}): string => {
  ['id', 'slug', 'key', 'title'].forEach(field => {
    if (params[field] !== undefined) {
      route = route.replace(`:${field}`, params[field]);
    }
  });
  return route;
};
