import { ReactComponent as AppStore } from './invite-appstore.svg'
import { ReactComponent as GooglePlay } from './invite-googleplay.svg'
import { ReactComponent as GetApp } from './get-app.svg'

const InviteIcon = {
  AppStore,
  GooglePlay,
  GetApp
};

export default InviteIcon