import React, { Component } from 'react';
import { Vendor } from '../../../../../model/data/Vendor';
import DataLoader from '../../../../../model/helpers/DataLoader';
import { Spinner, SpinnerType } from '../../../../views/spinner/Spinner';
import './VendorView.sass'

type Props = {
  vendor: Vendor
  onSelect: Function
};

type State = {
  hasImage?: boolean
}

class VendorView extends Component <Props, State> {

  state = {
    hasImage : undefined,
  };

  componentDidMount(): void {
    const { vendor } = this.props;

    if (!vendor.logo) {
      this.setState({ hasImage : false });
      return;
    }

    DataLoader.loadImage(vendor.logo.url)
      .then(() => this.setState({ hasImage : true }))
      .catch(() => this.setState({ hasImage : false }));
  }

  renderContent = () => {
    const { hasImage } = this.state;
    const { vendor } = this.props;

    if (hasImage === true) {
      return <img className='item__logo' src={vendor.logo!.url} alt=''/>
    } else if (hasImage === false) {
      return <div className='item__label'>{vendor.name}</div>;
    } else {
      return <Spinner type={SpinnerType.black} size={36}/>;
    }
  };

  render() {
    return (
      <div className='item' onClick={() => this.props.onSelect()}>
        <div className='item__content-wrapper'>
          <div className='item__content'>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

export default VendorView;
