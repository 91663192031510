import * as React from 'react';
import './InviteButton.sass'

type InviteButtonProps = {
  onClick: Function
}

class InviteButton extends React.Component<InviteButtonProps> {
  static defaultProps = {
    onClick : () => console.log('button Click')
  };

  onClick = () => {
    this.props.onClick();
  };

  render() {
    return <button className={`invite-button`} onClick={this.onClick}>{this.props.children}</button>
  }
}

export { InviteButton };

