import React, { Component, RefObject } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { Spring } from 'react-spring/renderprops';
import Icon from '../../../../../assets/Icon/Icon';
import { AccessPoint } from '../../../../../model/data/AccessPoint';
import { Image } from '../../../../../model/data/Image';
import { Unit } from '../../../../../model/data/Unit';
import { compose } from '../../../../../model/helpers/compose';
import FileHelper from '../../../../../model/helpers/FileHelper';
import {
  withKeyboardController,
  WithKeyboardControllerProps,
} from '../../../../../model/providers/KeyboardProvider/KeyboardProvider';
import Mutations from '../../../../../model/services/graphql/Mutations';
import { BackButton } from '../../../../views/backButton/BackButton';
import { Button, ButtonColors, ButtonTypes } from '../../../../views/button/Button';
import { Camera } from '../../../../views/camera/Camera';
import TextInput from '../../../../views/textInput/TextInput';
import { CodeType } from '../../CodeScreen';
import './CodeInput.sass';
import Tabs, { Tab } from './tabs/Tabs';
import { createLogger } from '../../../../../model/helpers/Logger';

const logger = createLogger('[CodeInput]');

type Props = WithApolloClient<{
  type: CodeType
  accessPoint: AccessPoint
  onBack: () => void,
  onGranted: (type: CodeType) => void,
  onDenied: (type: CodeType) => void
}> & WithKeyboardControllerProps;

type State = {
  type: CodeType,
  unitName: string,
  code: string,
  isLoading: boolean,
  codeInputError: boolean,
  unitInputError: boolean,
  forward: boolean,
  immediate: boolean
}

class CodeInput extends Component<Props, State> {

  state = {
    type           : this.props.type,
    unitName       : '',
    code           : '',
    isLoading      : false,
    codeInputError : false,
    unitInputError : false,
    forward        : true,
    immediate      : false,
  };

  unitRef = React.createRef<HTMLInputElement>();
  codeRef = React.createRef<HTMLInputElement>();
  webcamRef = React.createRef<Camera>();
  tabs = [
    new Tab('guest', 'I’m a Guest', <Icon.CodeGuest className='icon'/>),
    new Tab('resident', 'I’m a Resident', <Icon.CodeResident className='icon'/>)
  ];

  componentDidMount(): void {
    this.setFocused(this.refForType(this.state.type));

    setTimeout(() => {
      this.onSelectTab(this.tabs[this.state.type === CodeType.guest ? 0 : 1].id)
    }, Animated.showDuration())
  }

  refForType = (type: CodeType) => {
    return type === CodeType.guest ? this.codeRef : this.unitRef;
  };

  isValid = () => {
    if (this.state.type === CodeType.guest) {
      return this.isCodeValid();
    } else {
      return this.isCodeValid() && this.isUnitValid();
    }
  };

  isCodeValid = () => {
    return this.state.code.length > 0;
  }

  isUnitValid = () => {
    const { unitName } = this.state;
    const { property } = this.props.accessPoint;

    const units = property.units
      .filter((item: Unit) => {
        return item.name.toLowerCase() === unitName.toLowerCase()
      });

    return units.length > 0 && unitName.length > 0;
  }

  setFocused = (ref: RefObject<HTMLInputElement>) => {
    if (ref.current) ref.current.focus();
  };

  handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ([e.keyCode, e.which].includes(13)) {
      if (this.isValid()) this.onSendSelect()
    }
  };

  // API

  uploadBase64Image = (base64Image: string) => {
    const file = FileHelper.dataURLtoFile(base64Image, 'screenshot.jpg');
    const variables = { field : 'file', file : file };

    return this.props.client!.mutate({
        mutation  : Mutations.uploadFile,
        variables : variables
      })
      .then((response: any) => response.data.uploadFile)
      .catch((err) => logger.e(err));
  };

  openByGuestCodeWith = (image: Image) => {
    const { accessPoint, onGranted, onDenied } = this.props;
    const { code, type } = this.state;

    this.props.client!.mutate({
        mutation  : Mutations.openAccessPointWithCode,
        variables : {
          id      : accessPoint.id,
          type    : 'GUEST',
          code    : code,
          imageId : image.id
        }
      })
      .then((res: any) => {
        this.setState({ isLoading : false });
        res.data.openAccessPointWithCode === true ? onGranted(type) : onDenied(type)
      })
      .catch(() => {
        this.setState({ isLoading : false });
        onDenied(type);
      });
  };

  openByResidentCodeWith = (image: Image) => {
    const { accessPoint, onGranted, onDenied } = this.props;
    const { code, unitName, type } = this.state;

    const unit = accessPoint.property.units
      .filter((item: Unit) => {
        return item.name.toLowerCase() === unitName.toLowerCase()
      })
      .shift();

    this.props.client!.mutate({
        mutation  : Mutations.openAccessPointWithLockoutCode,
        variables : {
          id      : accessPoint.id,
          unitId  : unit!.id,
          code    : code,
          imageId : image.id
        }
      })
      .then((res: any) => {
        this.setState({ isLoading : false, forward : false, immediate : false });
        setTimeout(() => {
          res.data.openAccessPointWithLockoutCode === true ? onGranted(type) : onDenied(type)
        }, Animated.hideDuration())
      })
      .catch(() => {
        this.setState({ isLoading : false, forward : false, immediate : false }, () => {
          onDenied(type);
        });

      });
  };

  // Actions

  onChangeUnit = (value: string) => {
    this.setState({ unitName : value.toUpperCase(), unitInputError : false, immediate : true });
  };

  onChangeCode = (value: string) => {
    this.setState({ code : value.toUpperCase(), codeInputError : false, immediate : true });
  };

  onBackAction = () => {
    this.props.KeyboardController.hide()
    this.setState({
      forward   : false,
      immediate : false
    }, () => setTimeout(() => this.props.onBack(), Animated.hideDuration()));
  };

  onSelectTab = (id: string) => {
    const isGuest = id === this.tabs[0].id;
    const type = isGuest ? CodeType.guest : CodeType.resident;
    this.setState({ type : type, immediate : true }, () => this.setFocused(this.refForType(type)));
  };

  onSendSelect = async () => {
    const { type } = this.state;
    const isGuest = type === CodeType.guest;

    if (this.isValid()) {
      this.setState({ isLoading : true, immediate : true });
      this.props.KeyboardController.hide()

      const base64 = this.webcamRef.current!.captureImage();
      const image = await this.uploadBase64Image(base64) as Image;
      isGuest ? this.openByGuestCodeWith(image) : this.openByResidentCodeWith(image);

    } else if (isGuest) {
      this.setState({ codeInputError : !this.isCodeValid() });
    } else {
      this.setState({ unitInputError : !this.isUnitValid(), codeInputError : !this.isCodeValid() });
    }
  };

  render() {
    const { isLoading, type, unitName, code, unitInputError, codeInputError, forward, immediate } = this.state;
    const isGuest = type === CodeType.guest;
    const height = isGuest ? 0 : 130;
    const opacity = isGuest ? 0 : 1;

    return (
      <React.Fragment>
        <div className='code-input__back-wrapper'>
          <BackButton onClick={() => this.onBackAction()}/>
        </div>
        <div className='code-input'>
          <div className='cloud-wrapper'>
            <Animated.Cloud
              forward={forward.toString()}
              immediate={immediate.toString()}
              className='cloud'/>
            <Animated.Camera
              forward={forward.toString()}
              immediate={immediate.toString()}
              containerClassName='webcam'
              videoClassName='webcam-video'
              ref={this.webcamRef}/>
          </div>
          <div className='content'>
            <Tabs
              tabs={this.tabs}
              initialId={this.tabs[isGuest ? 0 : 1].id}
              onSelectTab={this.onSelectTab}
              forward={forward}/>
            <div className='inputs'>
              <div className='input-wrapper' style={{ height, opacity, transition : 'all 0.15s ease-in-out' }}>
                <Animated.InputLabel
                  forward={forward.toString()}
                  immediate={immediate.toString()}
                  delay={forward ? 50 : 50}
                  marginTop={forward ? 180 : 140}
                  className="label">{'Unit Number'}
                </Animated.InputLabel>
                <Animated.TextInput
                  forward={forward.toString()}
                  immediate={immediate.toString()}
                  delay={forward ? 50 : 50}
                  marginTop={forward ? 180 : 140}
                  className={unitInputError ? 'field-with-error' : 'field'}
                  name='unit'
                  inputRef={this.unitRef}
                  onChange={this.onChangeUnit}
                  value={unitName}
                  onKeyDown={this.handleEnter}/>
              </div>
              <div className='input-wrapper'>
                <Animated.InputLabel
                  forward={forward.toString()}
                  immediate={immediate.toString()}
                  delay={forward ? 100 : 0}
                  marginTop={forward ? 120 : 80}
                  className="label">{'Access Code'}
                </Animated.InputLabel>
                <Animated.TextInput
                  forward={forward.toString()}
                  immediate={immediate.toString()}
                  delay={forward ? 100 : 0}
                  marginTop={forward ? 120 : 80}
                  className={codeInputError ? 'field-with-error' : 'field'}
                  type='password'
                  autoComplete='new-password'
                  name='code'
                  inputRef={this.codeRef}
                  onChange={this.onChangeCode}
                  value={code}
                  onKeyDown={this.handleEnter}/>
              </div>
            </div>
            <Animated.Button
              className='send-button'
              forward={forward.toString()}
              immediate={immediate.toString()}
              delay={forward ? 150 : 0}
              marginTop={forward ? 60 : 30}
              height={110}
              type={ButtonTypes.filled}
              color={ButtonColors.green}
              loading={isLoading}
              onClick={this.onSendSelect}> Access as {type === CodeType.guest ? 'Guest' : 'Resident'}
            </Animated.Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  withApollo,
  withKeyboardController
)(CodeInput);

// Animation

const Animated = {

  showDuration : () => 300,
  hideDuration : () => 200,

  Cloud : (props: any) => {
    return <Spring
      config={{ tension : 175, friction : 17 }}
      immediate={props.immediate}
      reset={true}
      reverse={!props.forward}
      from={{ opacity : 1 }}
      to={{ opacity : 1 }}>
      {style => <Icon.Cloud {...props} style={style}/>}
    </Spring>
  },

  Camera : React.forwardRef<Camera, any>((props, ref) => {
    return <Spring
      config={{ tension : 170, friction : 15 }}
      immediate={props.immediate}
      reset={true}
      reverse={!props.forward}
      from={{ opacity : 0, marginTop : 110, transform : 'translate3d(-360px,0,0) scale(0.4)' }}
      to={{ opacity : 1, marginTop : 70, transform : 'translate3d(-410px,0,0) scale(1)' }}>
      {style => <div style={style}><Camera ref={ref} {...props} /></div>}
    </Spring>
  }),

  TextInput : React.forwardRef<HTMLInputElement, any>((props, ref) => {
    return <Spring
      config={{ tension : 150, friction : 15 }}
      immediate={props.immediate}
      reset={true}
      reverse={!props.forward}
      delay={props.delay}
      from={{ opacity : 0, marginTop : props.marginTop }}
      to={{ opacity : 1, marginTop : 0 }}>
      {style => <div style={style}><TextInput ref={ref} {...props}/></div>}
    </Spring>
  }),

  InputLabel : ({ marginTop, ...restProps }: any) => {
    return <Spring
      config={{ tension : 150, friction : 15 }}
      immediate={restProps.immediate}
      reset={true}
      reverse={!restProps.forward}
      delay={restProps.delay}
      from={{ opacity : 0, marginTop }}
      to={{ opacity : 1, marginTop : 0 }}>
      {style => <div {...restProps} style={style}/>}
    </Spring>
  },

  Button : (props: any) => {
    return <Spring
      config={{ tension : 150, friction : 15 }}
      immediate={props.immediate}
      reset={true}
      reverse={!props.forward}
      delay={props.delay}
      from={{ opacity : 0, marginTop : props.marginTop }}
      to={{ opacity : 1, marginTop : 0 }}>
      {style => <div style={style}><Button {...props} /></div>}
    </Spring>
  },
}
