import gql from 'graphql-tag';
import Fragment from './Fragment';

const Mutations: any = {
  registerCloudScreen : gql`
    mutation registerCloudScreen($name: String!, $deviceToken: String!, $isSandbox: Boolean!, $code: String) {
      registerCloudScreen(name: $name, deviceToken: $deviceToken, isSandbox: $isSandbox, code: $code) {
        id
        name
        status
        accessPoint {
          ...AccessPointFragment
        }
        createdAt
      }
    }
    ${Fragment.AccessPointFragment}
  `,

  initConnectionWithContact : gql`
    mutation initConnectionWithContact($data: InputContactConnection!) {
      initConnectionWithContact(data: $data) {
        connection {
          ...ConnectionFragment
        },
        token
      }
    }
    ${Fragment.ConnectionFragment}
    `,

  initConnectionWithPropertyManager : gql`
    mutation initConnectionWithPropertyManager($accessPointId: ID!, $caller: InputCaller!) {
      initConnectionWithPropertyManager(accessPointId: $accessPointId, caller: $caller) {
        connection {
          ...ConnectionFragment
        },
        token
      }
    }
    ${Fragment.ConnectionFragment}
    `,

  initConnectionWithLeasingManager : gql`
    mutation initConnectionWithLeasingManager($accessPointId: ID!, $caller: InputCaller!) {
      initConnectionWithLeasingManager(accessPointId: $accessPointId, caller: $caller) {
        connection {
          ...ConnectionFragment
        },
        token
      }
    }
    ${Fragment.ConnectionFragment}
    `,

  cancelConnection : gql`
    mutation cancelConnection($id: ID!) {
      cancelConnection(id: $id) {
        ...ConnectionFragment
      }
    }
    ${Fragment.ConnectionFragment}
    `,

  uploadFile : gql`
    mutation uploadFile($field: String) {
      uploadFile(field: $field) {
        id
        url
      }
    }`,

  openAccessPointWithCode : gql `
    mutation openAccessPointWithCode($id: ID!, $type: AccessCodeType!, $code: String!, $imageId: ID) {
      openAccessPointWithCode(id: $id, type: $type, code: $code, imageId: $imageId)
    }
  `,

  openAccessPointWithLockoutCode : gql `
    mutation openAccessPointWithLockoutCode($id: ID!, $unitId: ID!, $code: String!, $imageId: ID!) {
      openAccessPointWithLockoutCode(id: $id, unitId: $unitId, code: $code, imageId: $imageId)
    }
  `,

  openAccessPointWithVendorCode : gql `
    mutation openAccessPointWithVendorCode($id: ID!, $companyId: ID!, $code: String!, $imageId: ID!) {
      openAccessPointWithVendorCode(id: $id, companyId: $companyId, code: $code, imageId: $imageId)
    }
  `,

  sendAppLink : gql `
    mutation sendAppLink($phone: Phone!) {
      sendAppLink(phone: $phone)
    }
  `,
};

export default Mutations;
