import React, { Component, CSSProperties } from 'react';
import cn from 'classnames';
import './UserPic.sass';
import { Tenant } from '../../../model/data/Tenant';
import { User } from '../../../model/data/User';


type Props = {
  user: User | Tenant | any,
  className?: string,
  size: number,
  style?: CSSProperties
}

class UserPic extends Component<Props> {
  static defaultProps = {
    size  : 40,
    style : {}
  };

  render() {
    const { user, size, style, className } = this.props;
    let initials: string[] = [];
    if (user.name) {
      initials = user.name.split(' ');
    } else {
      initials = [user.firstName, user.lastName]
    }
    const initialsString = initials.filter(i => !!i).map(i => i[0]).join('');
    const sizeStyle = {
      width        : size,
      height       : size,
      borderRadius : size / 2
    };

    return (
      <div className={cn('user-pic', className)} style={{ ...sizeStyle, ...style }}>
        {user.image ? (
          <div className='user-pic_image' style={{ ...sizeStyle, backgroundImage : `url(${user.image.url})` }}/>
        ) : (
          <div className='user-pic__initials' style={{ ...sizeStyle, fontSize : size / 3 }}>
            {initialsString.toUpperCase()}
          </div>
        )}
      </div>
    );
  }

}

export default UserPic;
