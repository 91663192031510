import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppRoute } from '../../../components/app/AppRoute';
import { INACTIVE_DELAY } from '../../Constants';
import { compose } from '../../helpers/compose';
import { createLogger } from '../../helpers/Logger';
import { AuthControllerProps } from '../AuthProvider';

const logger = createLogger('[IdleProvider]');

const EXCEPTIONS = [AppRoute.pending, AppRoute.login, AppRoute.settings];

export interface IdleController {
  start: () => void,
  stop: () => void,
}

export interface IdleControllerProps {
  idleTimeoutController: IdleController
}

const IdleContext = React.createContext<IdleController>({
  start : () => null,
  stop  : () => null,
});

class IdleProvider extends Component<RouteComponentProps> {

  timer: any;

  componentDidMount() {
    this.start();
    document.addEventListener('click', this.handleTouch)
  }

  componentWillUnmount(): void {
    this.stop();
    document.removeEventListener('click', this.handleTouch);
  }

  start = () => {
    this.stop();
    this.timer = setTimeout(this.redirectToRoot, INACTIVE_DELAY);
  };

  stop = () => {
    clearTimeout(this.timer);
  };

  redirectToRoot = () => {
    if (EXCEPTIONS.includes(this.props.location.pathname)) {
      logger.i('[IDLE] Skipping redirect');
      return;
    }

    this.props.history.push(AppRoute.home)
  };

  handleTouch = () => {
    this.start();
  };

  render() {
    return (
      <IdleContext.Provider value={{
        start : this.start,
        stop  : this.stop,
      }}>
        {this.props.children}
      </IdleContext.Provider>
    )
  }
}

export const withIdleController = <P extends object>(WrappedComponent: React.ComponentType<P & IdleControllerProps>) =>
  class withIdleController extends React.Component<P & AuthControllerProps> {
    render() {
      return (
        <IdleContext.Consumer>
          {controller => <WrappedComponent {...this.props} idleTimeoutController={controller}/>}
        </IdleContext.Consumer>
      );
    }
  };

export default compose(
  withRouter,
)(IdleProvider);
