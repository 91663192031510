import { User } from '../data/User';

export const parseAddress = (address: any) => {
  const street = address.street ? address.street : '';
  const number = address.number ? address.number : '';
  const city = address.city ? address.city.name : '';
  const state = address.state ? address.state.name : '';
  const zip = address.zip ? address.zip : '';

  return `${number} ${street}, ${city}, ${state} ${zip}`
};

export const fullName = (user: User) => {
  return [user.firstName, user.lastName].filter(item => !!item).join(' ');
};