import React, { Component } from 'react';
import { Vendor } from '../../../../../model/data/Vendor';
import moment from 'moment';
import VendorView from './VendorView';
import './VendorsList.sass'

type Props = {
  vendors: Vendor[]
  onSelect: (vendor: Vendor) => void
  onFetch: Function
};

class VendorsList extends Component <Props> {

  componentDidMount(): void {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    if ((window.innerHeight + window.scrollY) < document.body.offsetHeight * 0.5) return;
    this.props.onFetch();
  };

  render() {
    const { vendors, onSelect } = this.props;

    return (
      <>
        <div className='list__info-wrapper'>
          <div className='list__label'>Select your company:</div>
          <div className='list__date'>{moment().format('dddd MMM DD, YYYY | h:mm A')}</div>
        </div>
        <div className='list' key={vendors.length}>
          {vendors.map((value, index) => <VendorView key={index} vendor={value} onSelect={() => onSelect(value)}/>)}
        </div>
      </>
    );
  }
}

export default VendorsList;
