export const createLogger = (Symbol: any) => ({
  debug : (...mixed: any) => console.debug(Symbol, ...mixed),
  i : (...mixed: any) => console.info(Symbol, new Date().toISOString(), ...mixed),
  e : (...mixed: any) => console.error(Symbol, ...mixed),
});

export const logger = createLogger('');

export const errorLogger = (name: any) => (stderr: any, error: any) => {
  if (error) {
    logger.e(name, stderr);
  }
};
