import { ApolloProvider } from '@apollo/client';
import { ContextFormProvider } from 'context-form/dist';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './components/app/App';
import FormTheme from './components/views/from/FormTheme';
import { client } from './model/services/graphql/ApolloClient';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ContextFormProvider theme={FormTheme}>
          <App/>
        </ContextFormProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
