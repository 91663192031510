import * as React from 'react';
import { createContext } from 'react';

export interface WithKeyboardControllerProps {
  KeyboardController: KeyboardController
}

export interface KeyboardController {
  visible: boolean,
  show: () => void,
  hide: () => void,
  addEventListener: (event: KEYBOARD_EVENT, callable: any) => void,
  removeEventListener: (event: KEYBOARD_EVENT, callable: any) => void,
  onKeyPressed: (key: string) => void
}

export enum KEYBOARD_EVENT {
  keyPressed = 'keyPressed',
  keyboardDown = 'keyboardDown'
}

export const KeyboardContext = createContext<{}>({
  visible : false
});

type State = {
  visible: boolean,
}

export default class KeyboardProvider extends React.Component<{}, State> {
  eventListeners: { [key: string]: any[] } = {
    [KEYBOARD_EVENT.keyPressed]   : [],
    [KEYBOARD_EVENT.keyboardDown] : [],
  };

  state = {
    visible : false,
  };

  addEventListener = (event: KEYBOARD_EVENT, cb: any) => this.eventListeners[event].push(cb);
  removeEventListener = (event: KEYBOARD_EVENT, cb: any) => this.eventListeners[event].filter(listener => listener !== cb);
  showKeyboard = () => this.setState({ visible : true });
  hideKeyboard = () => this.setState({ visible : false }, () => {
    this.eventListeners[KEYBOARD_EVENT.keyboardDown].forEach((cb) => cb());
  });
  onKeyPressed = (key: any) => this.eventListeners[KEYBOARD_EVENT.keyPressed].forEach((cb) => cb(key));

  render() {
    return (
      <KeyboardContext.Provider value={{
        visible             : this.state.visible,
        show                : this.showKeyboard,
        hide                : this.hideKeyboard,
        addEventListener    : this.addEventListener,
        removeEventListener : this.removeEventListener,
        onKeyPressed        : this.onKeyPressed
      }}>
        {this.props.children}
      </KeyboardContext.Provider>
    );

  }
}

export const withKeyboardController = <P extends object>(WrappedComponent: React.ComponentType<P & WithKeyboardControllerProps>) =>
  class WithKeyboardController extends React.Component<P & WithKeyboardControllerProps> {
    render() {
      return (
        <KeyboardContext.Consumer>
          {context => {
            return <WrappedComponent {...this.props} KeyboardController={context}/>;
          }}
        </KeyboardContext.Consumer>
      );
    }
  };
