import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Icon from '../../../assets/Icon/Icon';
import { Property } from '../../../model/data/Property';
import { parseAddress } from '../../../model/helpers/Data';
import { BackButton } from '../../views/backButton/BackButton';
import TextInput from '../../views/textInput/TextInput';
import './PndScreen.sass';

type Props = RouteComponentProps & {
  property: Property,
  searchQuery: string,
  onSearchChange: (searchQuery: string) => void
};

class PndHeader extends Component<Props> {

  render() {
    const { history, property, searchQuery } = this.props;
    const address = property ? parseAddress(property.address) : '';

    return (
      <div className='pnd-header'>
        <div className='pnd-header__back-button-wrapper'>
          <BackButton onClick={history.goBack}/>
        </div>
        <div className='pnd-header__info'>
          <Icon.CloudGeo/>
          <div>
            <div className='pnd-header__info-title'>Property Names Directory</div>
            <div className='pnd-header__info-address'>{address}</div>
          </div>
        </div>
        <div className='pnd-header__search'>
          <div className='pnd-header__search-input-wrapper'>
            <div style={{ display : 'flex', width : '90%', alignItems : 'center' }}>
              <Icon.Search style={{ marginRight : '30px', marginLeft: '10px', width: 32, height: 32 }}/>
              <TextInput
                className={'pnd-header__search-input'}
                placeholder={'Search by unit # or resident name...'}
                value={searchQuery}
                name={'search-input'}
                onChange={this.props.onSearchChange}
              />
            </div>
            {/*<Icon.Mic/>*/}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(PndHeader);
