import React, { Component } from 'react';
import Icon from '../../../../assets/Icon/Icon';
import { ACTION_BACK_TIMEOUT } from '../../../../model/Constants';
import { Button } from '../../../views/button/Button'
import './InviteFailed.sass';

type Props = {
  onComplete: Function
};

class InviteFailed extends Component<Props> {

  timeoutId: any;

  componentDidMount(): void {
    this.timeoutId = setTimeout(() => this.props.onComplete(), ACTION_BACK_TIMEOUT)
  }

  componentWillUnmount(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
  }

  render() {
    return (
      <div className='invite-failed-view'>
        <Icon.CloudSad style={{ width : 600, height: 400 }}/>
        <div className='title'>We’re Sorry!</div>
        <div className='subtitle'>
          Looks like we weren't able to connect our<br/>
          server. Please, try again in a few minutes.
        </div>
        <Button
          className='cancel-button'
          onClick={this.props.onComplete}
          height={100}>
          Try Again
        </Button>
      </div>
    );
  }
}

export default InviteFailed;
