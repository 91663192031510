import * as React from 'react';
import './BackButton.sass'
import Icon from '../../../assets/Icon/Icon';

interface BackButtonProps {
  size?: number,
  onClick: Function
  style?: object
}

class BackButton extends React.Component<BackButtonProps> {
  static defaultProps = {
    size    : 45,
    onClick: () => console.log('backButton button action'),
    style   : {},
  };

  onClick = () => this.props.onClick();

  render() {
    const { style, size } = this.props;

    return <button className='back-button' style={style} onClick={this.onClick}>
      <Icon.Back style={{ height : size, width : size }}/>
    </button>
  }
}

export { BackButton };
