import React, { Component } from 'react';
import {  Spring } from 'react-spring/renderprops';
import Icon from '../../../../../assets/Icon/Icon';
import { Button } from '../../../../views/button/Button'
import './CodeDenied.sass';
import { CodeType } from '../../CodeScreen';
import { ACTION_BACK_TIMEOUT } from '../../../../../model/Constants';

type Props = {
  type: CodeType
  onRetry: Function
  onComplete: Function
};

class CodeDenied extends Component<Props> {

  state = {
    forward : true,
    back    : false,
  }

  timeoutId: any;

  componentDidMount(): void {
    this.timeoutId = setTimeout(() => {
      this.props.onComplete()
    }, ACTION_BACK_TIMEOUT)
  }

  componentWillUnmount(): void {
    this.clearTimeout();
  }

  onBackAction = () => {
    this.clearTimeout();
    this.setState({ forward : false, back : true }, () =>
      setTimeout(() => this.props.onComplete(), 500));
  };

  onRetryAction = () => {
    this.clearTimeout();
    this.setState({ forward : false, back : true }, () =>
      setTimeout(() => this.props.onRetry(), 700));
  };

  clearTimeout = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  };

  render() {
    const { forward, back } = this.state;
    const isGuest = this.props.type === CodeType.guest;

    return (
      <div className='code-denied'>
        <Spring
          reset={true}
          reverse={!forward}
          from={{
            opacity   : forward ? 1 : back ? 0 : 1,
            width     : back ? 600 : 520,
            marginTop : forward ? 80 : back ? 400 : 80,
          }}
          to={{
            opacity   : 1,
            width     : 600,
            marginTop : 300
          }}>{props =>
          <Icon.CloudSad style={props} className='cloud'/>
        }</Spring>
        <Spring
          reset={true}
          reverse={!forward}
          from={{ opacity : 0, marginTop : back ? 60 : 30 }}
          to={{ opacity : 1, marginTop : 60 }}>{props =>
          <div style={props} className='code-denied__message'>
            { isGuest ?
              <div>
                <span>The Access Code is</span>
                <div>is <span className='code-denied__message-bold'>incorrect!</span></div>
              </div> :
              <div>
                <span>The Unit and Access Code</span>
                <div>combination is <span className='code-denied__message-bold'>incorrect!</span></div>
              </div>
            }
          </div>
        }</Spring>
        <Spring
          reset={true}
          reverse={!forward}
          from={{ opacity : 0}}
          to={{ opacity : 1}}>{props =>
          <Button style={props} className='code-denied__cancel-button' onClick={this.onRetryAction} height={100}>
            Try Again
          </Button>
        }</Spring>
      </div>
    );
  }
}

export default CodeDenied;
