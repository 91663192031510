export type AppEventCallback = (...args: any[]) => any

export enum AppEventType {
  ACTIVATED = 'activated',
  CONTENT_UPDATED = 'content_updated',
  DOOR_OPENED = 'door_opened',
  ACCESS_GRANTED = 'access_granted',
  ACCESS_DENIED = 'cs_access_denied',
  RESET = 'reset',
}

export type AppEventListener = {
  type: AppEventType,
  callback: AppEventCallback
}

class AppEventDispatcher {

  private listeners: AppEventListener[] = [];

  dispatch(type: AppEventType, data = {}) {
    this.listeners
      .filter(item => item.type === type)
      .forEach(item => item.callback(data));
  }

  addListener(type: AppEventType, callback: AppEventCallback) {
    this.listeners.push({
      type : type,
      callback : callback
    })
  }

  removeListener(type: AppEventType) {
    this.listeners = this.listeners.filter(item => item.type !== type)
  }
}

export default new AppEventDispatcher();