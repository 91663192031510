import io from 'socket.io-client';
import AppEventDispatcher, { AppEventType } from './AppEventDispatcher';
import { createLogger } from '../helpers/Logger';

const logger = createLogger('[WS]');

export enum WSActionType {
  auth      = 'auth',
  activated = 'cs_activated',
  opened    = 'cs_door_opened',
  updated   = 'cs_content_updated',
  granted   = 'cs_access_granted',
  denied    = 'cs_access_denied',
  updateFw  = 'cs_firmware_update'
}

enum ClientEvent {
  connect     = 'connect',
  disconnect  = 'disconnect',
  authRequest = 'auth_request',
  action      = 'action',
}

enum EmitAction {
  auth = 'auth',
  ping = 'ping',
}

type ActionData = {
  action: WSActionType,
  data: object
}

type WSListener = {
  action: WSActionType,
  callback: (...args: any[]) => any
}

class WebSocketClient {

  private readonly config = {
    uri      : process.env.REACT_APP_WS_URI,
    authType : 'CLOUD_SCREEN',
    opts     : {
      upgrade    : true,
      transports : ['websocket'],
    },
  };

  private readonly id: string | undefined;
  private client: any | undefined;

  private isConnected: boolean = false;
  private isAuthorized: boolean = false;
  private pingTimer: any | undefined;
  private shouldReconnect: boolean = true;
  private listeners: WSListener[] = [];

  constructor(id: string | undefined) {
    if (!id) {
      logger.e('Failed to setup WebSocket client with id: ' + id);
      return;
    }

    this.id = id;
    this.connect();
    this.keepAlive();
  };

  private connect = () => {
    if (!this.config.uri) {
      logger.e('WebSocket URI is not provided');
      return;
    }

    // If client already exists, better to close it
    if (this.client) {
      try {
        this.client.close();
        this.client = undefined;
      } catch (e) {
        // Do nothing
      }
    }

    this.client = io(this.config.uri, this.config.opts);
    this.client.on(ClientEvent.authRequest, this.onAuthRequest);
    this.client.on(ClientEvent.action, this.onAction);
    this.client.on(ClientEvent.connect, this.onConnect);
    this.client.on(ClientEvent.disconnect, this.onDisconnect);
  };

  private onConnect = () => {
    this.isConnected = true;
    logger.i('connected');
  };

  private onDisconnect = (e) => {
    logger.i('disconnected');
    this.isConnected = false;
    this.isAuthorized = false;

    setTimeout(() => {
      if (this.shouldReconnect) {
        this.connect();
      }
    }, 5000);
  };

  private onAuthRequest = () => {
    logger.i('on auth request');
    this.emitAction(EmitAction.auth, {
      type : this.config.authType,
      id   : this.id,
    })
  };

  private keepAlive = () => {
    if (this.isAuthorized) {
      this.client.emit('action', {
        action : 'ping',
        data   : {},
      })
    }
    setTimeout(this.keepAlive, 5000);
  }

  private emitAction = (type: EmitAction, params?: object) => {
    this.client.emit(type, params);
  };

  private onAction = (data: ActionData) => {
    logger.i('action', data);

    switch (data.action) {
      case WSActionType.auth:
        this.isAuthorized = true;
        logger.i('authorized!');
        AppEventDispatcher.dispatch(AppEventType.CONTENT_UPDATED);
        break;

      case WSActionType.activated:
        logger.i('activated!');
        AppEventDispatcher.dispatch(AppEventType.ACTIVATED, data.data);
        break;

      case WSActionType.opened:
        logger.i('opened!');
        AppEventDispatcher.dispatch(AppEventType.DOOR_OPENED);
        break;

      case WSActionType.updated:
        logger.i('update!');
        AppEventDispatcher.dispatch(AppEventType.CONTENT_UPDATED);
        break;

      case WSActionType.granted:
        logger.i('granted!');
        AppEventDispatcher.dispatch(AppEventType.ACCESS_GRANTED);
        break;

      case WSActionType.denied:
        logger.i('denied!');
        AppEventDispatcher.dispatch(AppEventType.ACCESS_DENIED);
        break;

      case WSActionType.updateFw:
        logger.i('update FW');
        break;

      default:
        logger.e(`not supported action! Action[${data.action}]`);
        break;
    }

    this.listeners
      .filter(item => item.action === data.action)
      .forEach((item: WSListener) => item.callback(data.data));
  };

  // Public methods

  on = (action: WSActionType, callback: (...args: any[]) => any) => {
    this.listeners.push({ action : action, callback });
  };

  off = (action: WSActionType) => {
    this.listeners = this.listeners.filter(item => item.action !== action);
  };

  forceClose = () => {
    this.shouldReconnect = false;

    if (this.client) {
      try {
        this.client.close();
        this.client = undefined;
        logger.i('Force close: Success.');
      } catch (e) {
        logger.e('Force close failed:', e);
      }
    } else {
      logger.i('Force close: Client already closed.');
    }
  };
}

export default WebSocketClient;

