import { createLogger } from '../helpers/Logger';

const logger = createLogger('[StorageService]');

class StorageService {

  prefix: string;
  data: any;
  dataPath: string = `storage`;

  constructor() {
    this.prefix = 'cloudscreen-electron-';
    this.data = this.parseDataFile();
  }

  getItem = (key: string): string | null => {
    return localStorage.getItem(this.prefix + key);
  };

  getJsonItem = <T>(key: string): T => {
    const data = localStorage.getItem(this.prefix + key);
    return data ? JSON.parse(data) : undefined;
  };

  setItem = (key: string, value?: string): void => {
    localStorage.setItem(this.prefix + key, value || '');
  };

  setJsonItem = (key: string, value: object): void => {
    localStorage.setItem(this.prefix + key, JSON.stringify(value));
  };

  removeItem = (key: string): void => {
    localStorage.removeItem(this.prefix + key);
  };

  updateData = (key: string, value: object | string | undefined) => {
    this.data[key] = value;
    try {
      localStorage.setItem(this.prefix + this.dataPath, JSON.stringify(this.data));
    } catch (e) {
      logger.e('[Storage] Failed to write file. Err:', e);
    }
  };

  parseDataFile = () => {
    try {
      const data = localStorage.getItem(this.prefix + this.dataPath);
      return data ? JSON.parse(data) : {};
    } catch (error) {
      logger.e('config parsing error', error);
      return {};
    }
  }
}

export default new StorageService();
