import React, { Component } from 'react';
import { config, Spring } from 'react-spring/renderprops'
import Icon from '../../../../../assets/Icon/Icon';
import { BackButton } from '../../../../views/backButton/BackButton';
import { CodeType } from '../../CodeScreen';
import './CodeTypeView.sass';

type Props = {
  initial: boolean,
  onBack: () => void,
  onComplete: (type: CodeType) => void,
};

class CodeTypeView extends Component<Props> {

  state = {
    initial : this.props.initial,
    forward : true,
    back    : false,
  };

  onBackAction = () => {
    this.setState({ forward : false, back : true, initial : false }, () =>
      setTimeout(() => this.props.onBack(), 180));
  };

  onResidentAction = () => {
    this.setState({ forward : false, initial : false }, () =>
      setTimeout(() => this.props.onComplete(CodeType.resident), 300));
  };

  onGuestAction = () => {
    this.setState({ forward : false, initial : false }, () =>
      setTimeout(() => this.props.onComplete(CodeType.guest), 300));
  };

  render() {
    const { initial, forward, back } = this.state;

    return (
      <>
        <div className='code-type__back-wrapper'>
          <BackButton onClick={() => this.onBackAction()}/>
        </div>
        <div className='code-type__content'>
          <Spring
            config={back ? config.default : config.stiff}
            reset={true}
            reverse={!forward}
            from={{
              opacity   : back || initial ? 0.5 : 1,
              marginTop : back ? 220 : 200,
            }}
            to={{ opacity : 1, marginTop : 220 }}>
            {props =>
              <Icon.Cloud style={props} className='cloud'/>
            }
          </Spring>
          <Spring
            config={back ? config.default : config.stiff}
            reset={true}
            reverse={!forward}
            from={{
              opacity   : 0,
              marginTop : back ? 220 : 180,
            }}
            to={{ opacity : 1, marginTop : 220 }}>
            {props =>
              <Icon.Smile style={props} className='smile'/>
            }
          </Spring>
          <Spring
            config={config.default}
            reset={true}
            reverse={!forward}
            from={{ opacity : 0.5, marginTop : back ? 120 : 160 }}
            to={{ opacity : 1, marginTop : 120 }}>
            {props =>
              <div style={props} className="code-type__text">
                Are you a Resident or a Guest?
              </div>
            }
          </Spring>
          <Spring
            config={{ tension : 270 }}
            reset={true}
            reverse={!forward}
            from={{ opacity : 0.5, marginTop : back ? 80 : 150 }}
            to={{ opacity : 1, marginTop : 0 }}>
            {props =>
              <div style={props} className='code-type__buttons'>
                <button className='code-type__guest' onClick={() => this.onGuestAction()}>
                  <Icon.CodeGuest style={{ width : 140, marginBottom : 25 }}/>
                  <br/>
                  I’m a Guest
                </button>
                <button className='code-type__resident' onClick={() => this.onResidentAction()}>
                  <Icon.CodeResident style={{ width : 64, marginBottom : 12 }}/>
                  <br/>
                  I’m a Resident
                </button>
              </div>
            }
          </Spring>
        </div>
      </>
    );
  }
}

export default CodeTypeView;
