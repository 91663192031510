import * as React from 'react';
import Icon from '../../../assets/Icon/Icon';
import './Spinner.sass';

export enum SpinnerType {
  white = 'white',
  black = 'black',
}

type Props = {
  type: SpinnerType
  size: number,
  style?: object
}

class Spinner extends React.Component<Props> {
  static defaultProps = {
    type : SpinnerType.white,
    size : 24,
  };

  render() {
    const { size, style, type } = this.props;

    const overriddenStyle = {
      ...style,
      ...(size ? { width  : size, height : size, } : {}),
      ...( type === SpinnerType.black ? { filter : 'brightness(0)'} : {})
    };

    return (
      <Icon.LoaderOval className='spinner' style={overriddenStyle}/>
    )
  }
}

export { Spinner };
