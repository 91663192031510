import * as React from 'react';
import KeyboardReact from 'react-simple-keyboard';
import {
  KEYBOARD_DISPLAY,
  KEYBOARD_LAYOUT,
  LAYOUT_KEY,
  LAYOUT_KEY_BY_INDEX,
  LAYOUT_TYPE,
} from '../../../model/providers/KeyboardProvider/KeyboardSettings';
import './Keyboard.sass'

type KeyboardProps = {
  title?: string,
  onKeyPressed: (key: string) => void,
  onDownKeyboard: () => void,
  collapsed: boolean
}

class Keyboard extends React.Component<KeyboardProps> {
  static defaultProps = {
    collapsed : true
  };

  state = {
    layoutName : LAYOUT_TYPE.default,
  };

  onKeyPress = (key: string) => {
    if (LAYOUT_KEY_BY_INDEX[key]) {
      this.handleLayoutChange(key);
    } else {
      this.props.onKeyPressed(key);
    }
  };

  handleLayoutChange = (key: string) => {
    let layoutName;
    switch (key) {
      case LAYOUT_KEY.downKeyboard:
        this.props.onDownKeyboard();
        layoutName = LAYOUT_TYPE.default;
        break;
      case LAYOUT_KEY.shiftActivated:
        layoutName = LAYOUT_TYPE.default;
        break;
      case LAYOUT_KEY.shift:
        layoutName = LAYOUT_TYPE.shift;
        break;
      case LAYOUT_KEY.default:
        layoutName = LAYOUT_TYPE.default;
        break;
      case LAYOUT_KEY.alt:
        layoutName = LAYOUT_TYPE.alt;
        break;
      case LAYOUT_KEY.expendedAlt:
        layoutName = LAYOUT_TYPE.expendedAlt;
        break;
      default:
        layoutName = LAYOUT_TYPE.default;
        break;
    }

    this.setState({ layoutName : layoutName });
  };

  render() {
    const { collapsed } = this.props;
    return (
      <KeyboardReact
        theme={`hg-theme-ios ${collapsed ? 'collapsed' : 'presented'}`}
        layout={KEYBOARD_LAYOUT}
        display={KEYBOARD_DISPLAY}
        layoutName={this.state.layoutName}
        useMouseEvents={false}
        preventMouseDownDefault={true}
        physicalKeyboardHighlight={true}
        physicalKeyboardHighlightBgColor={"#CBD3D9"}
        onKeyPress={(button: string) => this.onKeyPress(button)}
      />
    )
  }
}

export { Keyboard };

