import * as React from 'react';
import './Button.sass'
import { Spinner } from '../spinner/Spinner';
import cn from 'classnames';

export enum ButtonTypes {
  filled = 'filled',
  outlined = 'outlined',
}

export enum ButtonColors {
  green = 'green',
  white = 'white',
  red = 'red',
  clear = 'clear'
}

type ButtonProps = {
  title?: string,
  onClick: Function
  loading?: boolean,
  style?: object,
  height: number
  type: ButtonTypes,
  color: ButtonColors,
  className?: string,
  disabled?: boolean,
}

class Button extends React.Component<ButtonProps> {
  static defaultProps = {
    onClick   : () => console.log('button Click'),
    style     : {},
    height    : 100,
    type      : ButtonTypes.filled,
    color     : ButtonColors.green,
  };

  onClick = () => {
    this.props.onClick();
  };

  render() {
    const { title, loading, style, height, type, color, className, disabled } = this.props;
    const borderRadius = height / 2;
    const overriddenStyles = { ...style, height, borderRadius };

    return <button className={cn(`button button__${type}-${color}`, className)}
                   style={overriddenStyles}
                   onClick={this.onClick}
                   disabled={loading || disabled}>
      {loading
        ? <Spinner/>
        : title || this.props.children
      }
    </button>
  }
}

export { Button };

