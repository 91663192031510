import { withApollo } from '@apollo/client/react/hoc';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router'
import { AppRoute } from '../../components/app/AppRoute';
import { AccessPoint, AccessPointStatus } from '../data/AccessPoint';
import { CloudScreen, CloudScreenStatus } from '../data/CloudScreen';
import { compose } from '../helpers/compose';
import { clearSessionData } from '../helpers/ElectronUtils';
import queries from '../services/graphql/Queries';
import StorageService from '../services/StorageService';

export type LoginData = {
  cloudScreen: CloudScreen
}

export interface AuthController {
  isLoggedIn: boolean,
  cloudScreen?: CloudScreen,
  accessPoint?: AccessPoint,
  accessToken?: any,
  activate: (data: any) => void,
  deactivate: () => void,
  login: (authInfo: LoginData) => void,
  logout: () => void,
  fetchCloudScreen: () => void,
}

export interface AuthControllerProps {
  authController: AuthController
}

const AuthContext = React.createContext<AuthController>({
  isLoggedIn       : false,
  accessToken      : undefined,
  cloudScreen      : undefined,
  activate         : () => null,
  deactivate       : () => null,
  login            : () => null,
  logout           : () => null,
  fetchCloudScreen : () => null,
});

type State = {
  accessToken?: any,
  cloudScreen?: CloudScreen
}

export let authControllerInstance: AuthController;

export function withAuthProvider<P extends object>(WrappedComponent: React.ComponentType<P>) {
  class WithAuthProvider extends React.Component<P & AuthControllerProps & RouteComponentProps & { client: any }, State> {

    state = {
      cloudScreen : StorageService.getJsonItem<CloudScreen>('cloudScreen'),
      accessToken : StorageService.getItem('accessToken'),
    };

    get isLoggedIn() {
      return !!this.state.cloudScreen;
    }

    componentDidMount() {
      this.fetchCloudScreen();
    }

    fetchCloudScreen = () => {
      const { cloudScreen, accessToken } = this.state;
      if (!cloudScreen || !accessToken) return;

      this.props.client.query({
        fetchPolicy : 'network-only',
        query       : queries.cloudScreen,
        variables   : {
          id : cloudScreen.id
        }
      }).then((r: any) => {
        const { cloudScreen } = r.data;

        //if (cloudScreen && cloudScreen.status === CloudScreenStatus.active) {
        //  this.setState({ cloudScreen });
        //  StorageService.setJsonItem('cloudScreen', cloudScreen);
        //
        //  //this.props.history.push(AppRoute.maintenance);
        //} else {
        //  this.deactivate()
        //}

        if (cloudScreen) {
          StorageService.setJsonItem('cloudScreen', cloudScreen);
          this.setState({ cloudScreen });
          this.checkStatus(cloudScreen)
        } else {
          this.deactivate()
        }
      });
    };

    activate = (data: any) => {
      this.setState({
        accessToken : data.accessToken,
      }, () => {
        StorageService.setItem('accessToken', data.accessToken);
        StorageService.setItem('refreshToken', data.refreshToken);
        StorageService.setJsonItem('cloudScreen', this.state.cloudScreen);
        this.props.history.push(AppRoute.home);
        this.fetchCloudScreen();
      });
    };

    deactivate = () => {
      this.setState({ accessToken : null }, () => {
        StorageService.removeItem('accessToken');
        StorageService.removeItem('refreshToken');
        this.props.history.push(AppRoute.pending);
      })
    };

    login = (data: LoginData) => {
      this.setState({ cloudScreen : data.cloudScreen }, () => {
        StorageService.setJsonItem('cloudScreen', data.cloudScreen);
      });
    };

    logout = () => {
      this.setState({
        cloudScreen : undefined,
        accessToken : undefined
      }, () => {
        StorageService.removeItem('accessToken');
        StorageService.removeItem('refreshToken');
        StorageService.removeItem('cloudScreen');
        clearSessionData();
      });
    };

    checkStatus(cloudScreen: CloudScreen) {
      const csStatus = cloudScreen.status;
      const apStatus = cloudScreen.accessPoint.status;

      if (csStatus === CloudScreenStatus.new) {
        this.deactivate();
      } else if (apStatus !== AccessPointStatus.active) {
        this.props.history.push(AppRoute.maintenance);
      }
    }

    render() {

      const authController = {
        cloudScreen      : this.state.cloudScreen,
        accessPoint      : this.state.cloudScreen ? this.state.cloudScreen.accessPoint : undefined,
        accessToken      : this.state.accessToken,
        isLoggedIn       : this.isLoggedIn,
        activate         : this.activate,
        deactivate       : this.deactivate,
        login            : this.login,
        logout           : this.logout,
        fetchCloudScreen : this.fetchCloudScreen,
      };

      authControllerInstance = authController;

      return (
        <AuthContext.Provider value={authController}>
          <WrappedComponent {...this.props} authController={authController} />
        </AuthContext.Provider>
      );
    }
  }

  return compose(
    withApollo,
    withRouter
  )(WithAuthProvider);
}

export const withAuthController = <P extends object>(WrappedComponent: React.ComponentType<P & AuthControllerProps>) =>
  class WithAuthController extends React.Component<P & AuthControllerProps> {
    render() {
      return (
        <AuthContext.Consumer>
          {controller => <WrappedComponent {...this.props} authController={controller}/>}
        </AuthContext.Consumer>
      );
    }
  };
