import * as React from 'react';
import { SimpleTheme, ThemeInterface } from 'context-form';
import DatePickerComponent from './types/DatePickerComponent';
import SelectInputComponent from './types/SelectInputComponent';
//@ts-ignore
import dayjs from 'dayjs';

import './FormTheme.sass';

const FormTheme: ThemeInterface = {
  name : 'drafty',
  Form : SimpleTheme.Form,
  Field : {
    Container : SimpleTheme.Field.Container,
    Description : SimpleTheme.Field.Description,
    Label : SimpleTheme.Field.Label,
    Errors : SimpleTheme.Field.Errors,
    InputContainer : SimpleTheme.Field.InputContainer,
  },
  Footer : SimpleTheme.Footer,
  types : {
    text : {
      component : SimpleTheme.types.text.component,
    },
    checkbox : {
      component : SimpleTheme.types.checkbox.component,
    },
    view : {
      component : (props: any) => {
        let content = props.value;
        if (props.name === 'createdAt') {
          content = dayjs(props.value).format('MMM D, YYYY, hh:mm a');
        }
        return <span>{content}</span>
      },
    },
    textarea : {
      component : ({ hasError, ...props }: any) => <textarea {...props} rows={5}/>,
    },
    select : {
      component : SelectInputComponent,
    },
    password : {
      component : SimpleTheme.types.text.component,
    },
    tags : {
      component : SelectInputComponent,
    },
    date : {
      component : ({ hasError, ...props }: any) => <DatePickerComponent {...props}/>
    }
  },
};

export default FormTheme;
