const APP_VERSION: string = '0.1.0';
const API_VERSION: string = '0.2.0';
const PI_PATH: string = '/home/pi'
const CWD_PATH: string = `${PI_PATH}/fw_cs/current`;
const FW_ARCHIVES_PATH: string = `${PI_PATH}/fw_cs/archives`;
const FW_RELEASES_PATH: string = `${PI_PATH}/fw_cs/releases`;
const FW_DATA_PATH: string = `${PI_PATH}/fw_cs/data`;
const LOGS_PATH: string = process.env.REACT_APP_LOGS_PATH || '/var/log/ck/';

const BATCH_SIZE: number = 20;
const PHONE_MAX_LENGTH: number = 15;
const LOCKOUT_CODE: string = '13#*';
const RESET_CODE: string = '*#20120521#*';
const SETTINGS_CODE: string = '0000#*';
const ACTION_BACK_TIMEOUT: number = 8000;
const INACTIVE_DELAY = 60 * 1000;
const CAMERA_CAPTURE_RESOLUTION = {
  width: 1280,
  height: 720
};

export {
  APP_VERSION,
  API_VERSION,
  PI_PATH,
  CWD_PATH,
  FW_ARCHIVES_PATH,
  FW_RELEASES_PATH,
  FW_DATA_PATH,
  BATCH_SIZE,
  PHONE_MAX_LENGTH,
  LOCKOUT_CODE,
  RESET_CODE,
  SETTINGS_CODE,
  ACTION_BACK_TIMEOUT,
  INACTIVE_DELAY,
  CAMERA_CAPTURE_RESOLUTION,
  LOGS_PATH,
}
