import React from 'react';
import { compose } from '../../../model/helpers/compose';
import { AuthControllerProps, withAuthController } from '../../../model/providers/AuthProvider';
import KeyboardHolder from '../../views/keyboard/KeyboardHolder';
import NotificationHolder from '../../views/notification/NotificationHolder';
import './ScreenWrapper.sass';

class ScreenWrapper extends React.Component<AuthControllerProps, { notifications: any[] }> {
  render() {
    return (
      <div className='page-wrapper'>
        <NotificationHolder/>
        {this.props.children}
        <KeyboardHolder/>
      </div>
    );
  }
}

export default compose(
  withAuthController,
)(ScreenWrapper);
