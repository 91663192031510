import shortid from 'shortid';
import * as React from 'react';
import { createContext } from 'react';
//import { AuthControllerProps, withAuthController } from './AuthProvider';

export interface WithNotificationController {
  NotificationController: NotificationController
}

export interface Notification {
  message: string
  type?: NotificationType
}

export interface NotificationController {
  notifications: any[],
  show: (notification: Notification) => string
  hide: (id: string) => void
}

type State = {
  notifications: any[],
}

export enum NotificationType {
  OK = 'OK',
  ERROR = 'ERROR',
}

export const NotificationContext = createContext<{}>({
  notifications: [],
});

//class NotificationProvider extends React.Component {
//  render() {
//    return <h2>NotificationProvider</h2>
//  }
//}

export function withNotificationProvider<P extends object>(WrappedComponent: React.ComponentType<P & WithNotificationController>) {
  return class extends React.Component<{}, State> {

    state = {
      notifications: [],
    };

    WrappedComponent = withNotificationController(WrappedComponent);

    addNotification = (notification: Notification) => {
      const id = shortid.generate();
      this.setState((prevState) => ({
        notifications: [
          ...prevState.notifications,
          {
            id,
            type: NotificationType.OK,
            ...notification,
          },
        ],
      }));
      return id;
    };

    removeNotification = (id: any) => {
      this.setState((prevState: any) => ({
        notifications: prevState.notifications.filter((item: any) => item.id === id),
      }));
    };

    render() {
      const Component: any = this.WrappedComponent;
      return (
        <NotificationContext.Provider value={{
          notifications: this.state.notifications,
          show: this.addNotification,
          hide: this.removeNotification,
        }}>
          <Component {...this.props} />
        </NotificationContext.Provider>
      );

    }
  };
}

export const withNotificationController = <P extends object>(WrappedComponent: React.ComponentType<P & WithNotificationController>) =>
  class WithAuthController extends React.Component<P & WithNotificationController> {
    render() {
      return (
        <NotificationContext.Consumer>
          {context => {
            return <WrappedComponent {...this.props} NotificationController={context}/>;
          }}
        </NotificationContext.Consumer>
      );
    }
  };
