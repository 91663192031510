import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { CloudScreenStatus } from '../../model/data/CloudScreen';
import { compose } from '../../model/helpers/compose';
import { AuthControllerProps, withAuthProvider } from '../../model/providers/AuthProvider';
import { CallProvider } from '../../model/providers/CallProvider';
import { withNotificationProvider } from '../../model/providers/NotificationProvider';
import WebSocketClient from '../../model/services/WebSocketClient';
import { AppRoute } from './AppRoute';
import AppEventDispatcher, { AppEventType } from '../../model/services/AppEventDispatcher';
import { WebSocketProvider, withWebSocketClient, WithWebSocketProps } from '../../model/providers/WebSocketProvider';
import KeyboardProvider from '../../model/providers/KeyboardProvider/KeyboardProvider';
import IdleTimeoutProvider from '../../model/providers/IdleProvider/IdleProvider';
import CallScreen from '../screens/call/CallScreen';
import CodeScreen from '../screens/code/CodeScreen';
import EmergencyScreen from '../screens/emergency/EmergencyScreen';
import HomeScreen from '../screens/home/HomeScreen';
import MaintenanceScreen from '../screens/maintenance/MaintenanceScreen';
import PndScreen from '../screens/pnd/PndScreen';
import PendingScreen from '../screens/pending/PendingScreen';
import RegistrationScreen from '../screens/registration/RegistrationScreen';
import SettingsScreen from '../screens/settings/SettingsScreen';
import VendorsScreen from '../screens/vendors/VendorsScreen';
import ScreenWrapper from '../screens/wrapper/ScreenWrapper';
import '../../assets/styles/index.sass'
//import { TestScreen } from '../screens/test/TestScreen';
//import WelcomeScreen from '../screens/home/welcome/WelcomeScreen';

// todo: Gallery animations

class App extends React.Component<AuthControllerProps & WithWebSocketProps & RouteComponentProps> {

  client

  componentDidMount() {

    AppEventDispatcher.addListener(AppEventType.CONTENT_UPDATED, this.fetchCloudScreen);
    AppEventDispatcher.addListener(AppEventType.RESET, this.resetApp);
  }

  componentDidUpdate(prevProps: Readonly<AuthControllerProps & WithWebSocketProps & RouteComponentProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (this.props.authController?.cloudScreen?.id && !this.client) {
      this.client = new WebSocketClient(this.props.authController!.cloudScreen!.id);
    }
  }

  resetApp = () => {
    this.props.authController.logout();
  };

  fetchCloudScreen = () => {
    this.props.authController.fetchCloudScreen();
  };

  render() {
    const { cloudScreen, accessToken } = this.props.authController;

    return (
      <KeyboardProvider>
        <ScreenWrapper>
          {cloudScreen ? (
            <IdleTimeoutProvider>
              <WebSocketProvider client={this.client}>
                <CallProvider>
                  <CallScreen/>
                  <Switch>
                    <Route path={AppRoute.home} component={HomeScreen}/>
                    <Route path={AppRoute.settings} component={SettingsScreen}/>
                    <Route path={AppRoute.pending} component={PendingScreen}/>
                    <Route path={AppRoute.pnd} component={(props: any) => <PndScreen {...props}/>}/>
                    <Route path={AppRoute.code} component={(props: any) => <CodeScreen {...props}/>}/>
                    <Route path={AppRoute.vendors} component={(props: any) => <VendorsScreen {...props}/>}/>
                    <Route path={AppRoute.emergency} component={EmergencyScreen}/>
                    <Route path={AppRoute.maintenance} component={MaintenanceScreen}/>
                    {accessToken && cloudScreen.status === CloudScreenStatus.active ? (
                      <Redirect to={AppRoute.home}/>
                    ) : (
                      <Redirect to={AppRoute.pending}/>
                    )}
                  </Switch>
                </CallProvider>
              </WebSocketProvider>
            </IdleTimeoutProvider>
          ) : (
            <Switch>
              <Route path={AppRoute.login} component={RegistrationScreen}/>
              <Redirect to={AppRoute.login}/>
            </Switch>
          )}
        </ScreenWrapper>
      </KeyboardProvider>
    );
  }
}

export default compose(
  withAuthProvider,
  withNotificationProvider,
  withWebSocketClient,
)(App);
