const isElectron = () => {
  try {
    return navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;
  } catch (e) {
    return false;
  }
};

const getMediaPath = (audioSrc: string) => {
  if (isElectron()) {
    //@ts-ignore
    // const remote = window.require('electron').remote;
    // return `${remote.getGlobal('electronEnv').dirname}/static/media/${path.basename(audioSrc)}`;
    return audioSrc;
  } else {
    return audioSrc;
  }
};

const clearSessionData = () => {
  if (isElectron()) {
    //@ts-ignore
    const remote = window.require('electron').remote;

    remote.session.defaultSession.clearCache(() => null);
    remote.session.defaultSession.clearStorageData();
    remote.session.defaultSession.clearHostResolverCache();
  } else {
    //@todo something for web
  }
};

const requireNodeModule = (moduleName: string) => {
  if (isElectron()) {
    //@ts-ignore
    return window.require(moduleName)
  } else {
    try {
      return require(moduleName)
    } catch (e) {
      console.log(`Cannot import ${moduleName} at this platform`);
      return { error : 'Module is not accessible' };
    }
  }
};

//const IS_RPI_ELECTRON = process.env.REACT_APP_ELECTRON_ENV === 'rpi_desktop' && isElectron();
const IS_RPI_ELECTRON = false;

export {
  getMediaPath,
  isElectron,
  IS_RPI_ELECTRON,
  requireNodeModule,
  clearSessionData
}
