import React, { Component } from 'react';
import Icon from '../../../../assets/Icon/Icon';
import './InviteSuccess.sass';
import { ACTION_BACK_TIMEOUT } from '../../../../model/Constants';
import { Button } from '../../../views/button/Button';

type Props = {
  onComplete: Function
};

class InviteSuccess extends Component<Props> {

  timeoutId: any;

  componentDidMount(): void {
    this.timeoutId = setTimeout(() => this.props.onComplete(), ACTION_BACK_TIMEOUT)
  }

  componentWillUnmount(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
  }

  render() {
    return (
      <div className='invite-success-view'>
        <Icon.CloudSmile style={{ width : 600, height: 400 }}/>
        <div className="title">Thank You!</div>
        <div className='subtitle'>
          The link to download the CloudKeyz<br/>
          Mobile App was sent successfully.
        </div>
        <Button
          className='cancel-button'
          onClick={this.props.onComplete}
          height={100}>
          Back to Main Screen
        </Button>
      </div>
    );
  }
}

export default InviteSuccess;
