import * as React from 'react';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

type PropTypes = {
  onChange?: any,
  value?: any
}

class DatePickerComponent extends React.Component<PropTypes, {
  value: any
}> {

  onChange = (value: any) => {
    this.props.onChange(value ? value : null)
  };

  handleData = (value: any) => {
    if (typeof value === 'string') {
      value = new Date(value)
    }

    return value;
  };

  render() {
    const props = this.props;

    return <ReactDatePicker
      selected={this.handleData(props.value)}
      onChange={this.onChange}
    />
  }
}

export default DatePickerComponent;