import * as React from 'react';
import Select from "react-select";

type PropTypes = {
  isMulti?: boolean,
  onChange?: any,
  value?: any,
  options: any[],
  optionsPromise?: Promise<any[]>
}

class SelectInputComponent extends React.Component<PropTypes, {
  value: any
}> {

  options: any[] = [];

  constructor(props: any) {
    super(props);
    this.options = props.options;
    if (props.optionsPromise) {
      props.optionsPromise.then((options: any) => {
        this.options = options;
        this.setState({
          value: this.recalculateInitialValue()
        })
      });
    }
    this.state = {
      value: this.recalculateInitialValue()
    }
  }

  recalculateInitialValue = () => {
    let value: any = this.props.isMulti ? null : [];
    if (!this.props.value || !this.checkOptionsReady()) {
      return value;
    }
    if (this.props.isMulti) {
      value = this.options.filter((item: any) => this.props.value.includes(item.value))
    } else {
      value = this.options.find((item: any) => item.value === this.props.value) || null;
    }
    return value;
  };

  checkOptionsReady = () => {
    return !!(this.options && this.options.length);
  };

  componentDidUpdate(prevProps: Readonly<PropTypes>, prevState: Readonly<{ value: any }>, snapshot?: any): void {
    if (this.checkOptionsReady() && JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)) {
      this.options = this.props.options;
      const value = this.recalculateInitialValue();
      this.setState({
        value
      })
    }
  }

  onChange = (value: any) => {
    this.setState({
      value
    }, () => {
      if (this.props.isMulti) {
        this.props.onChange(value ? value.map((item: any) => item.value) : [])
      } else {
        this.props.onChange(value ? value.value : null)
      }

    });
  };

  render() {
    const props = this.props;

    return <Select
      {...props}
      options={this.options}
      onChange={this.onChange}
      value={this.state.value}
    />
  }
}

export default SelectInputComponent;