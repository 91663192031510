import React, { Component } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';

import { CallCaller } from '../../../model/data/CallCaller';
import { compose } from '../../../model/helpers/compose';
import { AuthControllerProps, withAuthController } from '../../../model/providers/AuthProvider';
import { CallProviderProps, withCallController } from '../../../model/providers/CallProvider';
import {
  IdleControllerProps,
  withIdleController,
} from '../../../model/providers/IdleProvider/IdleProvider';
import { withWebSocketClient } from '../../../model/providers/WebSocketProvider';
import CallView from './views/call/CallView';
import CallerView from './views/caller/CallerView';
import './CallScreen.sass'
import CallDeniedView from './views/denied/CallDeniedView';
import CallGrantedView from './views/granted/CallGrantedView';

enum ViewType {
  caller  = 'caller',
  call    = 'call',
  granted = 'granted',
  denied  = 'denied'
}

type Props = WithApolloClient<{
  callController: CallProviderProps
}> & AuthControllerProps & IdleControllerProps;

type State = {
  type: ViewType,
  caller: CallCaller | null
};

class CallScreen extends Component<Props, State> {

  state: any = {
    type   : ViewType.caller,
    caller : null,
    // type : ViewType.call,
    // caller: {
    //   "id" : "61ca057c1eb8a1001ef2a551",
    //   "type" : "CloudScreen",
    //   "name" : "yy",
    //   "imageId" : "61ca06492101a9001f4a1791",
    // },
  };

  onCallerComplete = (caller: CallCaller) => {
    this.props.idleTimeoutController.stop();
    this.setState({ caller : caller, type : ViewType.call })
  };

  onAccessGranted = () => {
    this.setState({ type : ViewType.granted });
  };

  onAccessDenied = () => {
    this.setState({ type : ViewType.denied });
  };

  finishCall = () => {
    this.setState({ type : ViewType.caller, caller : null });
    this.props.callController.finishCall();
    this.props.idleTimeoutController.start();
  };

  renderContent = () => {
    const { type } = this.state;
    const { callController, authController } = this.props;
    const recipient = callController.recipient;

    switch (type) {
      case ViewType.caller:
        return <CallerView
          onComplete={this.onCallerComplete}
          onBack={this.finishCall}
          recipient={recipient}/>;

      case ViewType.call:
        return <CallView
          onCancelled={this.finishCall}
          onAccessGranted={this.onAccessGranted}
          onAccessDenied={this.onAccessDenied}
          accessPoint={authController.accessPoint!}
          caller={this.state.caller}
          recipient={recipient}/>;

      case ViewType.granted:
        return <CallGrantedView
          accessPoint={authController.accessPoint!}
          onComplete={this.finishCall}/>;

      case ViewType.denied:
        return <CallDeniedView
          recipient={recipient}
          onComplete={this.finishCall}/>
    }
  };

  render() {
    if (!this.props.callController.recipient) {
      return null
    }

    return (
      <div className="call-screen">{this.renderContent()}</div>
    );
  }
}

export default compose(
  withCallController,
  withIdleController,
  withAuthController,
  withApollo,
  withWebSocketClient,
)(CallScreen);
