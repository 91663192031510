import React, { Component } from 'react';
import './EmergencyListItem.sass'
import { EmergencyItem } from '../../EmergencyScreen';

type Props = {
  item: EmergencyItem
  onSelect: Function
};

class EmergencyListItem extends Component <Props> {

  render() {
    const { item } = this.props;

    return (
      <div className='emergency-list-item' onClick={() => this.props.onSelect()}>
        <div className='emergency-list-item__content-wrapper'>
          <div className='emergency-list-item__content'>
            <img className='emergency-list-item__content-icon' src={item.icon} alt=''/>
            <div className='emergency-list-item__content-label' style={{ color: item.color }}>{item.text}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmergencyListItem;
