import { Tenant } from './Tenant';
import { User } from './User';

export enum CallRecipientType {
  tenant = 'tenant',
  manager = 'manager',
  leasing = 'leasing'
}

export class CallRecipient {

  type : CallRecipientType;
  user: User | Tenant | any;

  constructor(type: CallRecipientType, user: User | Tenant) {
    this.type = type;
    this.user = user
  }
}
