import React, { Component } from 'react';
import Icon from '../../../../../../assets/Icon/Icon';
import { CallRecipient } from '../../../../../../model/data/CallRecipient';
import { BackButton } from '../../../../../views/backButton/BackButton';
import { Button } from '../../../../../views/button/Button';
import TextInput from '../../../../../views/textInput/TextInput';

import './CallerNameView.sass';

type Props = {
  recipient: CallRecipient,
  onCancel: Function,
  onComplete: Function
};

type State = {
  name : string
};

class CallerNameView extends Component<Props, State> {

  inputRef = React.createRef<HTMLInputElement>();

  state = {
    name : ''
  };

  componentDidMount(): void {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  onChangeInput = (value: string) => this.setState({ name : value });

  onCompleteInput = () => {
    const { name } = this.state;
    this.props.onComplete(name);
  };

  render() {
    const { name } = this.state;
    const { recipient } = this.props;

    return (
      <div className='content-name'>
        <div className='back-button-wrapper'>
          <BackButton onClick={() => this.props.onCancel()}/>
        </div>
        <div className='content-name-header'>
          <Icon.CloudSmile style={{ width : 550, height: 'auto' }}/>
          <div className='content-name-title'>Please, Introduce Yourself</div>
          <div className='content-name-subtitle'>to {recipient.user.name}</div>
        </div>

        <div className='content-name-input'>
          <TextInput
            inputRef={this.inputRef}
            name={'name'}
            onChange={this.onChangeInput}
            value={name}
            placeholder={'Enter Your Name'}
            onEnter={this.onCompleteInput}
            onKeyDown={this.handleEnter}/>
        </div>
        <Button
          className='content-name__connect-button'
          height={100}
          disabled={!this.state.name}
          onClick={this.onCompleteInput}>
          <Icon.Bell style={{ width: '32px', height: 'auto', marginRight : '20px', marginTop: '2px' }}/>
          <span style={{ fontWeight : 600 }}>Connect</span>
        </Button>
      </div>
    );
  }

  handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ([e.keyCode, e.which].includes(13)) {
      this.onCompleteInput();
    }
  };
}

export default CallerNameView;
