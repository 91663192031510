import * as React from 'react'
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { RouterProps, withRouter } from 'react-router';
import { compose } from '../../../model/helpers/compose';
import { AuthControllerProps, withAuthController } from '../../../model/providers/AuthProvider';
import { BackButton } from '../../views/backButton/BackButton';
import Modal from '../../views/modal/Modal';
import EmergencyCode from './views/code/EmergencyCode';
import EmergencyDenied from './views/denied/EmergencyDenied';
import EmergencyGranted from './views/granted/EmergencyGranted';
import EmergencyList from './views/list/EmergencyList';
import Icon from '../../../assets/Icon/Icon';
import policeIcon from '../../../assets/images/police-icon.png'
import fireIcon from '../../../assets/images/fire-icon.png'
import emtIcon from '../../../assets/images/emt-icon.png'
import './EmergencyScreen.sass';

export type EmergencyItem = {
  id: string
  icon: any,
  text: string,
  color: string
}

type Props = WithApolloClient<{
  onBack: Function
  data?: any,
  history?: any
}> & RouterProps & AuthControllerProps;

type State = {
  type: ViewType
  items: EmergencyItem[],
  item: EmergencyItem | undefined,
}

enum ViewType {
  list = 'list',
  code = 'code',
  granted = 'granted',
  denied = 'denied'
}

class EmergencyScreen extends React.Component<Props, State> {

  state = {
    type        : ViewType.list,
    item      : undefined,
    items     : [
      { id : "police", icon : policeIcon, text : "POLICE", color: '#08496D' },
      { id : "fire", icon : fireIcon, text : "FIRE", color: '#FF2500' },
      { id : "emt", icon : emtIcon, text : "EMT", color: '#29ABE2' }
    ]
  };

  onBackAction = () => {
    this.state.type === ViewType.code ?
      this.setState({ type : ViewType.list, item : undefined }) :
      this.props.history.goBack();
  };

  onItemSelect = (item: EmergencyItem) => this.setState({ type : ViewType.code, item : item });
  onCodeGranted = () => this.setState({ type : ViewType.granted });
  onCodeDenied = () => this.setState({ type : ViewType.denied });
  onRetryAction = () => this.setState({ type : ViewType.code});
  onCompleteAction = () => this.props.history.goBack();

  renderContent = () => {
    const { type, items, item } = this.state;
    const { accessPoint } = this.props.authController;

    switch (type) {
      case ViewType.list:
        return <EmergencyList
          items={items}
          onSelect={this.onItemSelect}/>;

      case ViewType.code:
        return <EmergencyCode
          item={item}
          accessPoint={accessPoint!}
          onGranted={this.onCodeGranted}
          onDenied={this.onCodeDenied} />;

      case ViewType.granted:
        return <Modal>
          <EmergencyGranted
            accessPoint={accessPoint!}
            onComplete={this.onCompleteAction}/>
        </Modal>;

      case ViewType.denied:
        return <Modal>
          <EmergencyDenied
            onRetry={this.onRetryAction}
            onComplete={this.onCompleteAction}/>
        </Modal>;
    }
  };

  render() {
    return (
      <div className="emergency-screen">
        <div className='emergency-screen__header'>
          <div className='emergency-screen__back-button__wrapper'>
            <BackButton onClick={this.onBackAction}/>
          </div>
          <div className='emergency-screen__header-title'>EMERGENCY ACCESS ONLY</div>
          <Icon.Warning className='emergency-screen__header-warning-icon'/>
        </div>
        <div className='emergency-screen__content-wrapper'>
          <div className='emergency-screen__content'>
            { this.renderContent() }
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withApollo,
  withAuthController
)(EmergencyScreen);
