import { AccessPoint } from './AccessPoint';

export enum CloudScreenStatus {
  new = "NEW",
  active = "ACTIVE",
}

export interface CloudScreen {
  id: string
  name?: string
  status: CloudScreenStatus
  accessPoint: AccessPoint
  canContactManager: boolean
  canContactLeasingManager: boolean
}