import React, { Component } from 'react';
import Icon from '../../../../../assets/Icon/Icon';
import Sound from '../../../../../assets/sounds';
import { AccessPoint } from '../../../../../model/data/AccessPoint';
import { getMediaPath } from '../../../../../model/helpers/ElectronUtils';
import './EmergencyGranted.sass';


type Props = {
  accessPoint: AccessPoint
  onComplete: Function
};

class EmergencyGranted extends Component<Props> {

  timeoutId: any;
  soundRef: HTMLAudioElement = new Audio(getMediaPath(Sound.openSuccess));

  componentDidMount(): void {
    this.timeoutId = setTimeout(() => {
      this.props.onComplete()
    }, 8000)

    this.soundRef.play();
  }

  componentWillUnmount(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }

    this.soundRef.pause();
  }

  render() {
    const { accessPoint } = this.props;

    return (
      <div className='emergency-granted__wrapper' onClick={() => this.props.onComplete()}>
        <div className='emergency-granted'>
          <Icon.CloudSmile style={{ width : 600, height: 400 }}/>
          <div className="emergency-granted__text-title">Welcome!</div>
          <div className='emergency-granted__text-subtitle'>
            <span className='emergency-granted__text-door'>{accessPoint.name}</span> is open!
          </div>
        </div>
      </div>
    );
  }
}

export default EmergencyGranted;
