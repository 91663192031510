enum LAYOUT_TYPE {
  default = 'default',
  shift = 'shift',
  alt = 'alt',
  expendedAlt = 'expendedAlt'
}

enum LAYOUT_KEY {
  default = '{default}',
  shift = '{shift}',
  shiftActivated = '{shiftActivated}',
  alt = '{alt}',
  expendedAlt = '{expendedAlt}',
  downKeyboard = '{downKeyboard}'
}

const LAYOUT_KEY_BY_INDEX: any = {
  [LAYOUT_KEY.default]        : '{default}',
  [LAYOUT_KEY.shift]          : '{shift}',
  [LAYOUT_KEY.shiftActivated] : '{shiftActivated}',
  [LAYOUT_KEY.alt]            : '{alt}',
  [LAYOUT_KEY.expendedAlt]    : '{expendedAlt}',
  [LAYOUT_KEY.downKeyboard]   : '{downKeyboard}'
};

enum SPECIAL_KEY {
  space = '{space}',
  bksp = '{bksp}',
  clear = '{clear}'
}

const SPECIAL_KEY_BY_INDEX: any = {
  [SPECIAL_KEY.space] : '{space}',
  [SPECIAL_KEY.bksp]  : '{bksp}',
  [SPECIAL_KEY.clear] : '{clear}'
};

const KEYBOARD_LAYOUT = {
  [LAYOUT_TYPE.default]     : [
    `q w e r t y u i o p ${SPECIAL_KEY.bksp}`,
    `a s d f g h j k l ${SPECIAL_KEY.clear}`,
    `${LAYOUT_KEY.shift} z x c v b n m , . ${LAYOUT_KEY.shift}`,
    `${LAYOUT_KEY.downKeyboard} ${LAYOUT_KEY.alt} ${SPECIAL_KEY.space} ${LAYOUT_KEY.alt} ${LAYOUT_KEY.downKeyboard}`
  ],
  [LAYOUT_TYPE.shift]       : [
    `Q W E R T Y U I O P ${SPECIAL_KEY.bksp}`,
    `A S D F G H J K L ${SPECIAL_KEY.clear}`,
    `${LAYOUT_KEY.shiftActivated} Z X C V B N M , . ${LAYOUT_KEY.shiftActivated}`,
    `${LAYOUT_KEY.downKeyboard} ${LAYOUT_KEY.alt} ${SPECIAL_KEY.space} ${LAYOUT_KEY.alt} ${LAYOUT_KEY.downKeyboard}`
  ],
  [LAYOUT_TYPE.alt]         : [
    `1 2 3 4 5 6 7 8 9 0 ${SPECIAL_KEY.bksp}`,
    `@ # $ & * ( ) ' " ${SPECIAL_KEY.clear}`,
    `${LAYOUT_KEY.expendedAlt} % - + = / ; : ! ? ${LAYOUT_KEY.expendedAlt}`,
    `${LAYOUT_KEY.downKeyboard} ${LAYOUT_KEY.default} ${SPECIAL_KEY.space} ${LAYOUT_KEY.default} ${LAYOUT_KEY.downKeyboard}`
  ],
  [LAYOUT_TYPE.expendedAlt] : [
    `1 2 3 4 5 6 7 8 9 0 ${SPECIAL_KEY.bksp}`,
    `€ £ ¥ _ ^ [ ] { } ${SPECIAL_KEY.clear}`,
    `${LAYOUT_KEY.alt} § | ~ ... \\ < > ! ? ${LAYOUT_KEY.alt}`,
    `${LAYOUT_KEY.downKeyboard} ${LAYOUT_KEY.default} ${SPECIAL_KEY.space} ${LAYOUT_KEY.default} ${LAYOUT_KEY.downKeyboard}`
  ]
};

const KEYBOARD_DISPLAY = {
  [LAYOUT_KEY.default]        : 'ABC',
  [LAYOUT_KEY.alt]            : '.?123',
  [LAYOUT_KEY.expendedAlt]    : '#+=',
  [LAYOUT_KEY.shift]          : '⇧',
  [LAYOUT_KEY.shiftActivated] : '⬆',
  [LAYOUT_KEY.downKeyboard]   : 'hide',
  [SPECIAL_KEY.clear]         : 'return',
  [SPECIAL_KEY.bksp]          : '⌫',
  [SPECIAL_KEY.space]         : ' '
};

export {
  LAYOUT_TYPE,
  KEYBOARD_LAYOUT,
  KEYBOARD_DISPLAY,
  LAYOUT_KEY,
  LAYOUT_KEY_BY_INDEX,
  SPECIAL_KEY,
  SPECIAL_KEY_BY_INDEX
}