import * as React from 'react';
import { CallRecipient } from '../data/CallRecipient';

export type CallProviderProps = {
  recipient: CallRecipient | undefined,
  initiateCall: (recipient: CallRecipient) => void
  finishCall: () => void
}

type CallProviderState = {
  recipient: CallRecipient | undefined
}

const CallContext = React.createContext<CallProviderProps>({
  recipient    : undefined,
  // recipient   : {
  //   type : CallRecipientType.tenant,
  //   user : {
  //     firstName : 'Alex',
  //     lastName  : ' L',
  //     name      : 'Alex',
  //     imageId   : '5e9749b0e5e93a00284cbd5a',
  //     id        : '611463eb8feabd001ecffaa8',
  //     type      : 'AccessGroupMember',
  //   },
  // },
  initiateCall : () => null,
  finishCall   : () => null,
});

export class CallProvider extends React.Component<{}, CallProviderState> {

  state: CallProviderState = {
    recipient : undefined,
  };

  initiateCall = (recipient: CallRecipient) => {
    this.setState({ recipient });
  };

  finishCall = () => {
    this.setState({
      recipient : undefined,
    });
  };

  render() {
    return <CallContext.Provider value={{
      initiateCall : this.initiateCall,
      finishCall   : this.finishCall,
      recipient    : this.state.recipient,
    }}>
      {this.props.children}
    </CallContext.Provider>
  }
}

export const withCallController = <P extends object>(WrappedComponent: React.ComponentType<P & CallProviderProps>) => {

  return class WithCallController extends React.Component<P & CallProviderProps> {
    render() {
      return (
        <CallContext.Consumer>
          {(value: any) => <WrappedComponent callController={value} {...this.props}/>}
        </CallContext.Consumer>
      );
    }
  }
};
