import React, { Component } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import Icon from '../../../../../assets/Icon/Icon';
import { AccessPoint } from '../../../../../model/data/AccessPoint';
import { Image } from '../../../../../model/data/Image';
import moment from 'moment';
import { compose } from '../../../../../model/helpers/compose';
import FileHelper from '../../../../../model/helpers/FileHelper';
import { createLogger } from '../../../../../model/helpers/Logger';
import Mutations from '../../../../../model/services/graphql/Mutations';
import { Button, ButtonColors, ButtonTypes } from '../../../../views/button/Button';
import { Camera } from '../../../../views/camera/Camera';
import TextInput from '../../../../views/textInput/TextInput';
import { EmergencyItem } from '../../EmergencyScreen';
import './EmergencyCode.sass'

const logger = createLogger('[EmergencyCode]');

type Props = WithApolloClient<{
  item: EmergencyItem
  accessPoint: AccessPoint
  onGranted: Function
  onDenied: Function
}>;

type State = {
  isLoading: boolean
  code: string
  base64Image?: string
  image?: Image
};

class EmergencyCode extends Component <Props, State> {

  state = {
    isLoading   : false,
    code        : '',
    base64Image : undefined,
    image       : undefined
  };

  inputRef = React.createRef<HTMLInputElement>();
  webcamRef: any = React.createRef();

  componentDidMount(): void {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  onChangeInput = (value: string) => {
    this.setState({ code : value })
  };

  takePhoto = async () => {
    const base64 = this.webcamRef.captureImage();
    this.setState({ base64Image : base64 });

    await this.uploadBase64Image(base64)
      .then((response: any) => this.setState({ image : response.data.uploadFile as Image }))
      .catch((e: Error) => logger.e(e));
  };

  onSendSelect = async () => {
    const { code, image } = this.state;
    this.setState({ isLoading : true });

    if (image === undefined) {
      this.takePhoto().then(() => this.onSendSelect());
    } else {
      this.openAccessPoint(code, image)
        .then(r =>
          this.setState({ isLoading : false }, () =>
            r.data.openAccessPointWithCode === true ? this.props.onGranted() : this.props.onDenied()))
        .catch(e =>
          this.setState({ isLoading : false }, () => logger.e(e)));
    }
  };

  render() {
    const { item } = this.props;
    const { code, isLoading, base64Image } = this.state;

    return (
      <div className='emergency-code'>
        <span className='emergency-code__date'>{moment().format('dddd MMM DD, YYYY | h:mm A')}</span>
        <div className='emergency-code__content'>
          <div className='emergency-code__webcam-wrapper'>
            <div className='emergency-code__logo-wrapper'>
              <img className='emergency-code__logo' src={item.icon} alt=''/>
            </div>
            {base64Image
              ? <div className='emergency-code__webcam'><img src={base64Image} alt='webcam'/></div>
              : <Camera containerClassName='emergency-code__webcam' videoClassName='emergency-code__webcam-video' ref={ref => this.webcamRef = ref}/>
            }
            <button className='emergency-code__capture-button' onClick={() => this.takePhoto()}>
              <Icon.CamCapture style={{ width : '50%', height : 'auto', marginTop: '6px' }}/>
            </button>
          </div>
          <div className='emergency-code__input-wrapper'>
            <div className='emergency-code__input-title'>Enter your secure access code</div>
            <div className='emergency-code__input-field-wrapper'>
              <TextInput
                className='emergency-code__input-field'
                type='password'
                inputRef={this.inputRef}
                name={'code'}
                onChange={this.onChangeInput}
                value={code}
                placeholder={'Enter Code'}
                onKeyDown={this.handleEnter}/>
            </div>
            <Button className='emergency-code__send-button'
                    height={100}
                    type={ButtonTypes.filled}
                    color={ButtonColors.green}
                    disabled={!this.isCodeValid()}
                    loading={isLoading}
                    onClick={this.onSendSelect}>Submit</Button>
          </div>
        </div>
      </div>
    );
  }

  // Utils

  isCodeValid = () => {
    return this.state.code.length > 0;
  };

  handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ([e.keyCode, e.which].includes(13) && this.isCodeValid()) this.onSendSelect();
  };

  // API methods

  uploadBase64Image = (base64Image: string) => {
    const file = FileHelper.dataURLtoFile(base64Image, 'screenshot.jpg');
    const variables = { field : 'file', file : file };

    return this.props.client!.mutate({
        mutation  : Mutations.uploadFile,
        variables : variables
      })
  };

  openAccessPoint = (code: string, image: Image) => {
    const { accessPoint } = this.props;

    return this.props.client!.mutate({
        mutation  : Mutations.openAccessPointWithCode,
        variables : {
          id      : accessPoint.id,
          type    : 'EMERGENCY',
          code    : code,
          imageId : image.id
        }
      })
  };
}

export default compose(
  withApollo
)(EmergencyCode);
