import React, { Component } from 'react';
import WebSocketClient from '../services/WebSocketClient';

interface ProviderProps {
  cloudScreenId?: string,
  client?: WebSocketClient;
}

export interface WithWebSocketProps {
  webSocketClient: WebSocketClient
}

interface WebSocketController {
  webSocketClient: WebSocketClient | undefined
}

const WebSocketContext = React.createContext<WebSocketController>({
  webSocketClient : undefined,
});

class WebSocketProvider extends Component<ProviderProps> {

  client: WebSocketClient | undefined;

  constructor(props) {
    super(props);
    this.client = this.props.client;
  }


  componentWillUnmount(): void {
    if (this.client) {
      this.client.forceClose();
      this.client = undefined;
    }
  }

  render() {
    return (
      <WebSocketContext.Provider value={{
        webSocketClient : this.client
      }}>
        {this.props.children}
      </WebSocketContext.Provider>
    );
  }
}

const withWebSocketClient = <P extends object>(WrappedComponent: React.ComponentType<P & WithWebSocketProps>) =>
  class WithWebSocketClient extends React.Component<P & WithWebSocketProps> {
    render() {
      return (
        <WebSocketContext.Consumer>
          {controller => <WrappedComponent {...this.props} webSocketClient={controller.webSocketClient}/>}
        </WebSocketContext.Consumer>
      );
    }
  };

export { WebSocketProvider, withWebSocketClient };
