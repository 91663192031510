import { parsePhoneNumberFromString } from 'libphonenumber-js'
import * as React from 'react'
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import Icon from '../../../../assets/Icon/Icon';
import { LOCKOUT_CODE, PHONE_MAX_LENGTH, RESET_CODE, SETTINGS_CODE } from '../../../../model/Constants';
import { compose } from '../../../../model/helpers/compose';
import AppEventDispatcher, { AppEventType } from '../../../../model/services/AppEventDispatcher';
import Mutations from '../../../../model/services/graphql/Mutations';
import { Button, ButtonColors } from '../../../views/button/Button';
import './InviteInput.sass'

type Props = WithApolloClient<{
  type: string
  onBack: Function
  onLockout: Function
  onSettings: Function
  onSendSuccess: Function
  onSendFailure: Function
}>;

type State = {
  isLoading: boolean
  canReset: boolean
  input: string
}

class InviteInput extends React.Component<Props, State> {

  buttons: string[];

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading : false,
      canReset  : false,
      input     : ''
    };
    this.buttons = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
  }

  isValid = () => {
    const { input } = this.state;

    switch (input) {
      case LOCKOUT_CODE:
        return true
      case SETTINGS_CODE:
        return true
      case RESET_CODE:
        return true
      default:
        const numberFromString = parsePhoneNumberFromString(input, 'US');
        return numberFromString ? numberFromString.isValid() : false;
    }
  }

  onDialAction = (value: string) => {
    const { input } = this.state;
    const text = input + value;

    if (text.length <= PHONE_MAX_LENGTH) {
      this.setState({ input : text, canReset : text === RESET_CODE });
    }
  };

  onRemoveDialAction = () => {
    const { input } = this.state;
    const text = input.slice(0, -1);

    this.setState({ input : text, canReset : text === RESET_CODE });
  };

  onSendAction = () => {
    const { input } = this.state;
    const phoneNumber = parsePhoneNumberFromString(input, 'US');

    if (input === LOCKOUT_CODE) {
      this.props.onLockout()
    } else if (input === SETTINGS_CODE) {
      this.props.onSettings()
    } else if (input === RESET_CODE) {
      AppEventDispatcher.dispatch(AppEventType.RESET);
      this.props.onBack();
    } else if (phoneNumber) {
      this.querySendInvite(phoneNumber.format('E.164'))
    }
  };

  renderItem(value: string) {
    return (
      <div className='list-item' key={value}>
        <div className='list-item__content'>
          <button className='list-item__button' onClick={() => this.onDialAction(value)}>
            <div className='list-item__button-label'>{value}</div>
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { input, isLoading, canReset } = this.state;
    const numberFromString = parsePhoneNumberFromString(input, 'US');
    const isValid = this.isValid();
    const isEmpty = input.length === 0;
    const codes = [LOCKOUT_CODE, SETTINGS_CODE, RESET_CODE];
    const dialText = isValid && !codes.includes(input) ? numberFromString!.formatNational() : input;

    return (
      <div className='invite-view'>
        <img className='hand' alt=''/>
        <div className='content'>
          <button
            className='close-button'
            onClick={() => this.props.onBack()}>
            <Icon.Close style={{ width : '100%', height : '100%' }}/>
          </button>
          <div className='title'>Enter your phone number</div>
          <div className='subtitle'>and we will text you a link to download<br/>the CloudKeyz Mobile App.</div>
          <div className='dial'>
            <div className='phone'
                 style={{ opacity : isEmpty ? 0.5 : 1.0 }}>{isEmpty ? '(***) *** ****' : dialText}</div>
            {isEmpty ? null :
              <button
                className='remove-button'
                onClick={() => this.onRemoveDialAction()}>
                <Icon.DialDelete style={{ width : '100%', height : '100%' }}/>
              </button>
            }
          </div>
          <div className='list'>
            {this.buttons.map((value) => this.renderItem(value))}
          </div>
          <Button
            className='send-button'
            height={90}
            color={canReset ? ButtonColors.red : ButtonColors.green}
            loading={isLoading}
            disabled={!isValid}
            onClick={() => this.onSendAction()}>
            {canReset ? 'Reset' : 'Send App Message'}
          </Button>
          <div className='hint'>* Text Messaging rates may apply.</div>
        </div>
      </div>
    )
  }

  querySendInvite = (phone: string) => {
    const { onSendFailure, onSendSuccess } = this.props;

    this.setState({ isLoading : true });
    this.props.client!.mutate({
      mutation  : Mutations.sendAppLink,
      variables : {
        phone : phone
      }
    })
      .then((resp: any) => {
        this.setState({ isLoading : false }, () =>
          resp.data.sendAppLink === true ? onSendSuccess() : onSendFailure());
      })
      .catch(() => {
        this.setState({ isLoading : false }, () => onSendFailure());
      });
  };
}

export default compose(
  withApollo
)(InviteInput);
