import { Property } from './Property';

export enum AccessPointStatus {
  new = "NEW",
  active = "ACTIVE",
  offline = "OFFLINE",
  maintenance = "MAINTENANCE"
}

export type AccessPoint = {
  id: string,
  name: string,
  status: AccessPointStatus,
  allowVendors: boolean
  property: Property
}