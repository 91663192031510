import React from 'react';
import './Notification.sass';
import { compose } from '../../../model/helpers/compose';
import {
  NotificationType,
  WithNotificationController,
  withNotificationController,
} from '../../../model/providers/NotificationProvider';
import Icon from '../../../assets/Icon/Icon';

export type NotificationProps = {
  message: string
  autoClose?: boolean
  closeTimeout?: number
  key: string
  type: string
} & WithNotificationController;

const IconMap: any = {
  [NotificationType.OK] : () => <Icon.Check style={{ marginRight: 7, color: '#77bb41', width: 24 }}/>,
  [NotificationType.ERROR] : () => <Icon.Error style={{ marginRight: 7, color: '#f85959', width: 24 }}/>,
};

const defaultProps = {
  autoClose: true,
  closeTimeout: 3000,
};

class BasicNotification extends React.Component<NotificationProps> {
  static defaultProps = defaultProps;

  componentDidMount() {
    if (this.props.autoClose) {
      setTimeout(() => {
        this.props.NotificationController.hide(this.props.key)
      }, this.props.closeTimeout);
    }
  }

  render() {
    const Icon = IconMap[this.props.type];
    return (
      <div className='notification popup-mode'>
        <Icon/>{this.props.message}
      </div>
    );
  }
}

export default compose(
  withNotificationController,
)(BasicNotification);
