import gql from 'graphql-tag';

const Fragment = {
  UserFragment : gql`
    fragment UserFragment on User {
      id
      firstName
      lastName
      email
      phone
      company
    }
  `,
  DeviceTokenFragment : gql`
    fragment DeviceTokenFragment on DeviceToken {
      id
      type
      title
      value
      createdAt
    }
  `,
  AccessPointFragment : gql`
    fragment AccessPointFragment on AccessPoint {
      id
      name
      description
      property {
        id
        name
      }
    }
  `,
  PropertyFragment : gql`
    fragment PropertyFragment on Property {
      id
      type
      name
      image {
        url
      }
      logo {
        url
      }
      address {
        street
        number
        state {
          name
        }
        city {
          name
        }
        country
        zip
      }
      units {
        id
        name
      }
    }
  `,

  TenantFragment : gql`
    fragment TenantFragment on Tenant {
      id
      firstName
      lastName
      unit {
        id
        name
      }
      image {
        url
      }
    }
  `,
  PageInfoFragment : gql`
    fragment PageInfoFragment on PageInfo {
      pageCount
      pageNumber
      pageSize
      total
    }
  `,

  ConnectionFragment : gql`
    fragment ConnectionFragment on Connection {
      id
      status 
    }
  `,

  UnitFragment : gql`
    fragment UnitFragment on Unit {
      id
      name
    }
  `,

  VendorFragment : gql`
    fragment VendorFragment on Vendor {
      id
      name
      logo {
        id
        url
      }
    }
  `,
  FirmwareFragment : gql`
    fragment FirmwareFragment on Firmware {
      id
      version
      checksum
      type
      file {
        id
        url
      }
    }
  `,
};

export default Fragment;
